import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../App";
import "./Login.scss";
import ForgotPasswordPopup from "../components/ForgotPasswordPopup";
import SignInImage from "../images/SignInPageDesign2.png";
import SignUpImage from "../images/SignInPageDesign3.png";
import { createUser, getUserDetail } from "../services/api.services";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Login = ({}) => {
  const { state, dispatch } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
  const navigate = useNavigate();
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());
  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
  });

  const [signUpData, setSignUpData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    organisation: "",
    password: "",
  });

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const organisationRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const SignInEmailRef = useRef(null);
  const SignInPasswordRef = useRef(null);

  function getCurrentScreenWidth() {
    return window.innerWidth;
  }

  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);

  const emailRegex = new RegExp(
    `[a-z0-9À-ÖØ-öø-ÿ._%+-]+@[a-zÀ-ÖØ-öø-ÿ.-]+\\.[a-z]{2,}`,
    "i"
  );
  const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/;
  const organisationRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/;

  useEffect(() => {
    if (confirmPassword === signUpData.password) setConfirmPasswordValid(true);
    else setConfirmPasswordValid(false);

    if (
      signUpData.password.length !== 0 &&
      (signUpData.password.length < 8 || signUpData.password.length > 16)
    ) {
      setError("Password length should be between 8 to 16 characters");
    } else if (confirmPassword !== "" && !confirmPasswordValid) {
      setError(`Password doesn't match !!`);
    } else {
      setError("");
    }
  }, [signUpData, confirmPassword, confirmPasswordValid]);

  const handleSignUpFirstName = (e) => {
    setSignUpData((prevData) => ({ ...prevData, first_name: e.target.value }));
  };
  const handleSignUpLastName = (e) => {
    setSignUpData((prevData) => ({ ...prevData, last_name: e.target.value }));
  };
  const handleSignUpEmail = (e) => {
    setSignUpData((prevData) => ({ ...prevData, email: e.target.value }));
  };
  const handleSignUpPassword = (e) => {
    setSignUpData((prevData) => ({ ...prevData, password: e.target.value }));
  };
  const handleSignUpOrganisation = (e) => {
    setSignUpData((prevData) => ({
      ...prevData,
      organisation: e.target.value,
    }));
  };

  const handleSigninToggle = () => {
    document
      .getElementById("main-container")
      .classList.remove("right-panel-active");
    setError("");
  };
  const handleSignupToggle = () => {
    document
      .getElementById("main-container")
      .classList.add("right-panel-active");
    setError("");
  };

  const handleRespSignupToggle = () => {
    document.getElementById("app_authresp").classList.add("sign_up");
    document.getElementById("app_authresp").classList.remove("sign_in");
  };
  const handleRespSigninToggle = () => {
    document.getElementById("app_authresp").classList.add("sign_in");
    document.getElementById("app_authresp").classList.remove("sign_up");
  };

  const handleSignInEmail = (e) => {
    setError("");
    setSignInData((prevData) => ({ ...prevData, email: e.target.value }));
  };
  const handleSignInPassword = (e) => {
    setError("");
    setSignInData((prevData) => ({ ...prevData, password: e.target.value }));
  };

  const handleSignIn = async (payload) => {
    const toastId = toast.loading("Logging in ...");
    await getUserDetail(payload)
      .then((resp) => {
        toast.dismiss(toastId);
        if (resp.data.isSuccess) {
          toast.success("Logged In ....");

          dispatch({
            type: "LOGIN",
            payload: {
              user: payload,
              token: resp.data.token,
              is_admin: resp.data.is_admin,
              is_organisation_pricing_plan:
                resp.data.is_organisation_pricing_plan,
            },
          });
          if (resp.data.is_admin) {
            if (resp.data.is_organisation_pricing_plan) {
              navigate("/admin");
            } else {
              navigate("/pricing_plan_selector");
            }
          }
        } else {
          toast.error("Something went wrong!");
          setError(resp.data.message);
        }
      })
      .catch((err) => {
        toast.dismiss(toastId);
        // console.log(err);
        toast.error("Something went wrong!");
      });
  };
  const handleSignUp = async () => {
    const toastId = toast.loading("Creating New User...");
    try {
      const organisation = signUpData.organisation.trim().replace(/\s+/g, " ");
      const first_name = signUpData.first_name.trim().replace(/\s+/g, " ");
      const last_name = signUpData.last_name.trim().replace(/\s+/g, " ");
      const email = signUpData.email.trim().replace(/\s+/g, " ");
      if (!nameRegex.test(first_name)) {
        setError("Invalid First Name!!");
        toast.dismiss(toastId);
        return;
      }
      if (!nameRegex.test(last_name)) {
        setError("Invalid Last Name!!");
        toast.dismiss(toastId);
        return;
      }
      if (!emailRegex.test(email)) {
        setError("Invalid Email Id!!");
        toast.dismiss(toastId);
        return;
      }
      if (!organisationRegex.test(organisation)) {
        setError("Invalid Organisation Name Format!!");
        toast.dismiss(toastId);
        return;
      }
      const resp = await createUser(signUpData);
      toast.dismiss(toastId);
      if (resp.data.isSuccess) {
        toast.success("Registered Successfully!");
        handleSigninToggle();
        handleSignIn({
          email: signUpData.email,
          password: signUpData.password,
        });
        setSignUpData({
          first_name: "",
          last_name: "",
          email: "",
          organisation: "",
          password: "",
        });
        setConfirmPassword("");
      } else {
        // console.log("Error message:", resp.data.message);
        toast.error(resp.data.message);
      }
    } catch (err) {
      // console.error("Error:", err);
      toast.dismiss(toastId);
      toast.error("Something went wrong");
    }
  };

  const checkCreateUserValidity = () => {
    if (
      signUpData.first_name === "" ||
      signUpData.last_name === "" ||
      signUpData.email === "" ||
      signUpData.organisation === "" ||
      signUpData.password === "" ||
      !confirmPasswordValid ||
      confirmPassword === "" ||
      error !== ""
    )
      return true;
  };
  const checkSignInUserValidity = () => {
    if (signInData.email === "" || signInData.password === "") {
      return true;
    }
  };

  const handleKeyPress = (e, nextInputRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextInputRef && nextInputRef.current) {
        nextInputRef.current.focus();
      } else {
        if (deviceWidth > 520) {
          if (
            document
              .getElementById("main-container")
              .classList.contains("right-panel-active")
          ) {
            checkCreateUserValidity()
              ? setError("Please enter all fields")
              : handleSignUp();
          } else {
            checkSignInUserValidity()
              ? setError("Please fill all details")
              : handleSignIn(signInData);
          }
        } else {
          if (
            document
              .getElementById("app_authresp")
              .classList.contains("sign_up")
          ) {
            checkCreateUserValidity()
              ? setError("Please enter all fields")
              : handleSignUp();
          } else {
            checkSignInUserValidity()
              ? setError("Please fill all details")
              : handleSignIn(signInData);
          }
        }
      }
    }
  };
  return (
    <div className="login-outer_body">
      {deviceWidth > 520 ? (
        <div className="login-outer_body-container">
          <div className="login-register" id="main-container">
            <div className="register-container">
              <div className="login-register_form">
                <h1>Create Account</h1>
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={signUpData.first_name}
                  onChange={handleSignUpFirstName}
                  onKeyDown={(e) => handleKeyPress(e, lastNameRef)}
                  ref={firstNameRef}
                ></input>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={signUpData.last_name}
                  onChange={handleSignUpLastName}
                  onKeyDown={(e) => handleKeyPress(e, emailRef)}
                  ref={lastNameRef}
                  required
                ></input>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={signUpData.email}
                  onChange={handleSignUpEmail}
                  onKeyDown={(e) => handleKeyPress(e, organisationRef)}
                  ref={emailRef}
                  required
                ></input>
                <input
                  type="text"
                  placeholder="Organisation"
                  name="organisation"
                  value={signUpData.organisation}
                  onChange={handleSignUpOrganisation}
                  onKeyDown={(e) => handleKeyPress(e, passwordRef)}
                  ref={organisationRef}
                  required
                ></input>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={signUpData.password}
                  onChange={handleSignUpPassword}
                  onKeyDown={(e) => handleKeyPress(e, confirmPasswordRef)}
                  ref={passwordRef}
                  required
                ></input>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  name="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  ref={confirmPasswordRef}
                  required
                ></input>
                {error !== "" && (
                  <div className="register-container_form-error">{error}</div>
                )}
                <button
                  className="login-register_form-button"
                  onClick={() => {
                    checkCreateUserValidity()
                      ? setError("Please enter all fields")
                      : handleSignUp();
                  }}
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div className="login-container">
              <div className="login-register_form">
                <h1>Sign In</h1>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleSignInEmail}
                  onKeyDown={(e) => handleKeyPress(e, SignInPasswordRef)}
                  ref={SignInEmailRef}
                ></input>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleSignInPassword}
                  onKeyDown={(e) => handleKeyPress(e)}
                  ref={SignInPasswordRef}
                ></input>
                {error !== "" && (
                  <div className="login-container_form-error">{error}</div>
                )}
                <button
                  className="login-register_form-button"
                  type={"submit"}
                  onClick={() => {
                    checkSignInUserValidity()
                      ? setError("Please fill all details")
                      : handleSignIn(signInData);
                  }}
                >
                  Sign In
                </button>
                <ForgotPasswordPopup />
              </div>
            </div>
            <div className="overlay-container">
              <div className="overlay-container_body">
                <div className="overlay-container_body-left">
                  <h1>Member of Care Learner?</h1>
                  <p>
                    Happy to see you again. Sign in to continue the growth with
                    us
                  </p>
                  <button
                    className="login-register_form-button"
                    id="login"
                    onClick={() => handleSigninToggle()}
                  >
                    Sign In
                  </button>
                  <img
                    className="overlay-container_body-left-image"
                    alt="signinimage"
                    src={SignInImage}
                  ></img>
                </div>
                <div className="overlay-container_body-right">
                  <h1>New to Care Learner?</h1>
                  <p>
                    We are happy you are here. Complete sign up process to grow
                    with us.
                  </p>
                  <button
                    className="login-register_form-button"
                    id="register"
                    onClick={() => handleSignupToggle()}
                  >
                    Sign Up
                  </button>
                  <img
                    className="overlay-container_body-right-image"
                    alt="signupimage"
                    src={SignUpImage}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="app_authresp" className="app-authresp sign_in">
          <div className="app-authresp-content">
            <div className="app-authresp-content_login">
              <h1>Member of Care Learner?</h1>
              <p>
                Happy to see you again. Sign in to continue the growth with us
              </p>
              <img
                className="overlay-container_body-left-image"
                alt="signinimage"
                src={SignInImage}
              ></img>
            </div>
            <div className="app-authresp-content_register">
              <h1>New to Care Learner?</h1>
              <p>
                We are happy you are here. Complete sign up process to grow with
                us.
              </p>
              <img
                className="overlay-container_body-right-image"
                alt="signupimage"
                src={SignUpImage}
              ></img>
            </div>
          </div>
          <div className="app-authresp-form">
            <div className="app-authresp-form_login">
              <h1>Sign In</h1>
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={(e) => handleSignInEmail(e)}
              ></input>
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={(e) => handleSignInPassword(e)}
              ></input>
              {error !== "" && (
                <div className="login-container_form-error">{error}</div>
              )}
              <button
                className="login-register_form-button"
                type={"submit"}
                onClick={() => {
                  checkSignInUserValidity()
                    ? setError("Please fill all details")
                    : handleSignIn(signInData);
                }}
              >
                Sign In
              </button>
              <p>
                Don't have an Account?{" "}
                <b onClick={() => handleRespSignupToggle()}>Sign up here</b>
              </p>
            </div>
            <div className="app-authresp-form_register">
              <h1>Create Account</h1>
              <input
                type="text"
                placeholder="First Name"
                name="first_name"
                onChange={(e) => handleSignUpFirstName(e)}
                required
              ></input>
              <input
                type="text"
                placeholder="Last Name"
                name="last_name"
                onChange={(e) => handleSignUpLastName(e)}
                required
              ></input>
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={(e) => handleSignUpEmail(e)}
                required
              ></input>
              <input
                type="text"
                placeholder="Organisation"
                name="organisation"
                value={signUpData.organisation}
                onChange={(e) => handleSignUpOrganisation(e)}
                required
              ></input>
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={(e) => handleSignUpPassword(e)}
                required
              ></input>
              <input
                type="password"
                placeholder="Confirm Password"
                name="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              ></input>
              {error !== "" && (
                <div className="register-container_form-error">{error}</div>
              )}
              <button
                className="login-register_form-button"
                onClick={() => {
                  checkCreateUserValidity()
                    ? setError("Please enter all fields")
                    : handleSignUp();
                }}
              >
                Sign Up
              </button>
              <p>
                Already a member?{" "}
                <b onClick={() => handleRespSigninToggle()}>Login in here</b>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
