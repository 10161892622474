import React, { useState, useEffect, useContext, useRef } from "react";
import "./Subject.scss";
import SubjectContent from "./SubjectContent";
import {
  getCourseChapter,
  createChapterProgress,
  updateChapterProgress,
  getCourseModule,
} from "../services/api.services";
import toast from "react-hot-toast";
import { AuthContext } from "../App";
import RadialProgressBar from "../components/RadialProgressBar";

const Subject = ({ moduleId, moduleName, moduleData, courseName }) => {
  const divRef = useRef(null);
  const [moduleChapters, setModuleChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const { state, dispatch } = useContext(AuthContext);
  const [chapterDetails, setChapterDetails] = useState(null);
  const [key, setKey] = useState(true);
  const [routeData, setRouteData] = useState({});

  useEffect(() => {
    getModuleDetails(moduleId);
    findNextModule();
    divRef.current.scrollTo({ top: 0, behavior: "smooth" });
  }, [moduleName, key]);

  useEffect(() => {
    if (selectedChapter) {
      divRef.current.scrollTo({ top: 0, behavior: "auto" });
      getChapterDetails(selectedChapter.id);
    }
    
  }, [selectedChapter]);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getModuleDetails = async (module) => {
    await getCourseModule(header, module)
      .then((resp) => {
        if (resp.data.isSuccess) {
          setModuleChapters(resp.data.dataInfo.chapter);
        } else {
          console.log("Module Doesnt exist");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const getChapterDetails = async (id) => {
    await getCourseChapter(header, id)
      .then((resp) => {
        if (resp.data.isSuccess) {
          setChapterDetails(resp.data.dataInfo);
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const initateChapterProgress = async (chapter_id) => {
    await createChapterProgress(header, chapter_id)
      .then((resp) => {
        if (resp.data.isSuccess) {
          // console.log("Chapter Started!");
        } else {
          // console.log("Chapter in Progress!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const setChapterProgress = async (chapter_id) => {
    initateChapterProgress(chapter_id);
    const payload = { progress: 35 };
    await updateChapterProgress(header, chapter_id, payload)
      .then((resp) => {
        if (resp.data.isSuccess) {
          // console.log("Progess Updated to 35%!");
        } else {
          // console.log("Already in progress!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };

  const findNextModule = () => {
    if (moduleData && moduleData.length > 0) {
      const currentModule = moduleData.find(module => module.id === moduleId);
      if (currentModule) {
        const nextModule = moduleData.find(module => module.position === currentModule.position + 1);
        if (nextModule) {
          setRouteData({
            nextModuleRoute: `/${courseName}/${nextModule.name}`,
            navbarIndex: nextModule.position
          });
        } else {
          setRouteData({
            nextModuleRoute: `/${courseName}/${currentModule.name}`,
            navbarIndex: currentModule.position
          });
        }
      } else {
        console.log("Current module not found");
      }
    } else {
      console.log("Module data is empty or undefined");
    }
  };

  useEffect(() => {
    setSelectedChapter(null);
  }, [moduleChapters]);

  const renderModuleContainer = () => {
    return moduleChapters.map((data, index) => {
      return (
        <div
          key={data.id}
          className="app-subject_body-content"
          onClick={() => {
            if (data.progress !== null || data.position == 1) {
              handleChapterDetailSelector(data);
              setChapterProgress(data.id);
            } else {
              // Show a toast message or handle the disabled state as needed
              toast.error("Complete the previous chapter first.");
            }
          }}
        >
          <div className="app-subject_body-content-number">
            Chapter {index + 1}
          </div>
          <div className="app-subject_body-content-info">
            <div className="app-subject_body-content-info-title">
              {data.name}
            </div>
            {data.description ? (
              <p>{data.description}</p>
            ) : (
              <p>
                Welcome to this exciting new chapter. Get ready to engage in
                dynamic discussions and hands-on activities. Get ready to take
                your learning to the next level!
              </p>
            )}
          </div>
          <div className="app-subject_body-content-progress">
            <RadialProgressBar
              progressId={data.id}
              initialValue={data.progress}
              isComplete={data.is_complete}
            />
          </div>
        </div>
      );
    });
  };

  const handleChapterDetailSelector = async (chapter) => {
    if (selectedChapter && selectedChapter.id === chapter.id) {
      // If the same chapter is clicked again, fetch the details again
      getChapterDetails(chapter.id);
    } else {
      // If a different chapter is clicked, update the selected chapter
      setSelectedChapter(chapter);
    }
  };

  return (
    <div ref={divRef} className="app-parent-container">
      {selectedChapter ? (
        <SubjectContent
          initiateChapterProgress={setChapterProgress}
          handleChapterDetailSelector={handleChapterDetailSelector}
          chapterDetails={chapterDetails}
          setSelectedChapter={setSelectedChapter}
          moduleChapters={moduleChapters}
          header={header}
          customKey={key}
          setKey={setKey}
          routeData={routeData}
        />
      ) : (
        <div className="app-subject">
          <div className="app-subject_body">
            <div className="app-subject_body-title">
              <h2>{moduleName}</h2>
            </div>
            {moduleChapters && renderModuleContainer()}
          </div>
        </div>
      )}
    </div>
  );
};

export default Subject;
