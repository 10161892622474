import React, { useState, useEffect } from "react";
import {
  FaUpload,
  FaCheck,
  FaRegFileExcel,
  FaRegCalendarAlt,
  FaHistory,
  FaTimesCircle,
  FaUsers,
  FaBook,
} from "react-icons/fa";
import "./DynamicContainer.scss";

const DynamicContainer = ({
  isFileUpload,
  taskStatus,
  fileData,
  containerData,
  onClose,
}) => {
  const [status, setStatus] = useState("");
  const [fileInfo, setFileInfo] = useState({});

  useEffect(() => {
    if (taskStatus) {
      const status = mapStatus(taskStatus);
      setStatus(status);
    }
    if (fileData) {
      setFileInfo(fileData);
    }
  }, [fileData, taskStatus]);

  const mapStatus = (apiStatus) => {
    switch (apiStatus) {
      case "pending":
        return "Processing";
      case "success":
        return "Completed";
      case "error":
        return "Failed";
      default:
        return "";
    }
  };

  const getStatusClass = () => {
    switch (status) {
      case "Processing":
        return "processing";
      case "Failed":
        return "failed";
      case "Completed":
        return "completed";
      default:
        return "";
    }
  };

  const getStatusIcon = () => {
    if (isFileUpload) {
      switch (status) {
        case "Processing":
          return <FaHistory style={{ color: getStatusTextColor(status) }} />;
        case "Failed":
          return <FaUpload style={{ color: getStatusTextColor(status) }} />;
        case "Completed":
          return <FaCheck style={{ color: getStatusTextColor(status) }} />;
        default:
          return null;
      }
    } else {
      return <FaUsers style={{ color: getStatusTextColor(status) }} />;
    }
  };

  const getStatusTextColor = (status) => {
    if (isFileUpload) {
      switch (status) {
        case "Processing":
          return "#05177D";
        case "Failed":
          return "#D13737";
        case "Completed":
          return "#37D1AE";
        default:
          return "#000";
      }
    } else {
      return "#000";
    }
  };

  const handleFileDownload = () => {
    if (status === "Failed") {
      const link = document.createElement("a");
      link.href = fileInfo.fileUrl;
      link.download = fileInfo.fileName;
      link.click();
    }
  };

  const handleClose = () => {
    onClose();
    setStatus("");
  };

  return (
    <div className={`dynamic-container ${getStatusClass()}`}>
      {(status === "Failed" || status === "Completed") && isFileUpload && (
        <FaTimesCircle
          className="close_button"
          style={{ color: getStatusTextColor(status), cursor: "pointer" }}
          onClick={handleClose}
        />
      )}
      <div className="dynamic-container-body">
        <div className="dynamic-container-body-file_status">
          <div className="dynamic-container-body-file_status-text">
            <div className="dynamic-container-body-file_status-text-title">
              {isFileUpload ? "File Upload" : "Total Trainees"}
            </div>
            <div
              className="dynamic-container-body-file_status-text-status"
              style={{ color: getStatusTextColor(status) }}
            >
              {isFileUpload ? status : containerData["total_trainee"]}
            </div>
          </div>
          <div className="dynamic-container-body-file_status-icon">
            {getStatusIcon()}
          </div>
        </div>
        <div className="dynamic-container-body-file_info">
          <div className="dynamic-container-body-file_info-text">
            <div className="dynamic-container-body-file_info-text-title">
              {isFileUpload ? "File Name" : "Total Courses"}
            </div>
            <div
              className="dynamic-container-body-file_info-text-file_name ellipsis"
              title={
                isFileUpload
                  ? fileInfo.fileName
                  : containerData["total_enrolled_courses"]
              }
            >
              {isFileUpload
                ? fileInfo.fileName
                : containerData["total_enrolled_courses"]}
            </div>
          </div>
          <div className="dynamic-container-body-file_info-icon">
            <div className="dynamic-container-body-file_info-icon-svg">
              {isFileUpload ? (
                <FaRegFileExcel style={{ color: getStatusTextColor(status) }} />
              ) : (
                <FaBook style={{ color: getStatusTextColor(status) }} />
              )}
            </div>
            {isFileUpload && status === "Failed" && (
              <div
                className="dynamic-container-body-file_info-icon-error"
                style={{ color: "black" }}
                onClick={handleFileDownload}
              >
                Check Error
              </div>
            )}
          </div>
        </div>
        <div className="dynamic-container-body-file_date">
          <div className="dynamic-container-body-file_date-text">
            <div className="dynamic-container-body-file_date-text-title">
              {isFileUpload ? "Date" : "Completed v/s Assigned"}
            </div>
            <div className="dynamic-container-body-file_date-text-date">
              {isFileUpload
                ? fileInfo.date
                : containerData.completed_vs_assigned}
            </div>
          </div>
          <div className="dynamic-container-body-file_date-icon">
            {isFileUpload ? (
              <FaRegCalendarAlt style={{ color: getStatusTextColor(status) }} />
            ) : (
              <FaCheck style={{ color: getStatusTextColor(status) }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicContainer;
