import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FlippingCard from "./FlippingCard";
import "./CustomArrows.scss";
import "./CardCarousal.scss"


function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={style} onClick={onClick}>
        <div className="arrow next">→</div>
      </div>
    );
  }
  
  function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={style} onClick={onClick}>
        <div className="arrow prev">←</div>
      </div>
    );
  }
const CardCarousal = ({cards}) => {
  var settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // waitForAnimate: false,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  
  return (
    <div className="slider-container">
      <Slider
        {...settings}
        style={{
          width: "90%",
        }}
      >
        {cards.map((card, index) => (
          <FlippingCard key={index} content={card} />
        ))}
      </Slider>
    </div>
  );
};

export default CardCarousal;


