import React, {useEffect, useContext, useState} from 'react';
import {AuthContext} from '../../App';
import './AdminDashboardElements.scss';
import traineeIcon from '../../images/icons/admin/trainee.png';
import coursesIcon from '../../images/icons/admin/course.png';
import creditIcon from '../../images/icons/admin/credit.png';
import planIcon from '../../images/icons/admin/plan.png';
import {getAdminDashboardData} from '../../admin/services/api.services';
import toast from "react-hot-toast";
import AppLoader from '../../components/AppLoader';
import CourseProgress from '../../components/CourseProgress';
import { FaCircle } from "react-icons/fa";
import { FaStar } from "react-icons/fa";

const AdminDashboardElements = () => {
    const { state, dispatch } = useContext(AuthContext);
    const [dashboardData, setDashboardData] = useState(null)
    const [loader, setLoader] = useState(true)

    let header = {
        Authorization: "Token " + state.token,
        };

    useEffect(() => {
        const fetchDashboardData = async () => {
            try{
                const response = await getAdminDashboardData(header);
                if (response.data.isSuccess) {
                    setDashboardData(response.data.dataInfo)
                    setLoader(false)
                  } else {
                    toast.error(response.data.message);
                  }
            }catch(error){
                console.error("Error fetching credit details:", error);
            }
        }

        fetchDashboardData()
    }, [])

  return (
    <>
    {loader ? <AppLoader></AppLoader> :
    <div className='admin-dashboard-elements'>
        <div className='admin-dashboard-elements-title'>Dashboard</div>
        <div className='admin-dashboard-elements_body'>
            <div className='admin-dashboard-elements_body-appInfo'>
                <div className='admin-dashboard-elements_body-appInfo-container'>
                   <div className='admin-dashboard-elements_body-appInfo-container-data'>
                        <span className='admin-dashboard-elements_body-appInfo-container-data-title'>Total Trainees</span>
                        <span className='admin-dashboard-elements_body-appInfo-container-data-count'>{dashboardData.total_trainees}</span>
                   </div>
                   <div className='admin-dashboard-elements_body-appInfo-container-icon trainee-icon'>
                        <img src={traineeIcon} alt='trainees'></img>
                   </div>
                </div>
                <div className='admin-dashboard-elements_body-appInfo-container'>
                   <div className='admin-dashboard-elements_body-appInfo-container-data'>
                        <span className='admin-dashboard-elements_body-appInfo-container-data-title'>Total Enrolled Courses</span>
                        <span className='admin-dashboard-elements_body-appInfo-container-data-count'>{dashboardData.total_enrolled_course}</span>
                   </div>
                   <div className='admin-dashboard-elements_body-appInfo-container-icon course-icon'>
                        <img src={coursesIcon} alt='courses'></img>
                   </div>
                </div>
                <div className='admin-dashboard-elements_body-appInfo-container'>
                   <div className='admin-dashboard-elements_body-appInfo-container-data'>
                        <span className='admin-dashboard-elements_body-appInfo-container-data-title'>Credit Balance</span>
                        <span className='admin-dashboard-elements_body-appInfo-container-data-count'>{dashboardData.subscription_plan == 'Essential' ? dashboardData.credit_balance : 'Unlimited'}</span>
                   </div>
                   <div className='admin-dashboard-elements_body-appInfo-container-icon credit-icon'>
                        <img src={creditIcon} alt='credit'></img>
                   </div>
                </div>
                <div className='admin-dashboard-elements_body-appInfo-container'>
                   <div className='admin-dashboard-elements_body-appInfo-container-data'>
                        <span className='admin-dashboard-elements_body-appInfo-container-data-title'>Subscription Plan</span>
                        <span className='admin-dashboard-elements_body-appInfo-container-data-count'>{dashboardData.subscription_plan}</span>
                   </div>
                   <div className='admin-dashboard-elements_body-appInfo-container-icon plan-icon'>
                        <img src={planIcon} alt='plan'></img>
                   </div>
                </div>
            </div>
            <div className='admin-dashboard-elements_body-cqcInfo'>
                <div className='admin-dashboard-elements_body-cqcInfo-title'>Care Quality Commission (CQC)</div>
                <div className='admin-dashboard-elements_body-cqcInfo-details'>
                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratings'>
                        <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-title'>Current Rating</div>
                        <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-body'>
                            <ul className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list'>
                                <li className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item'>
                                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item-key'>Caring</div>
                                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item-value outstanding'>
                                        <FaStar />
                                        <p>Outstanding</p>
                                    </div>
                                </li>
                                <li className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item'>
                                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item-key'>Effective</div>
                                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item-value no-rating'>
                                        <FaCircle />
                                        <p>No Rating</p>
                                    </div>
                                </li>
                                <li className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item'>
                                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item-key'>Responsive</div>
                                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item-value good'>
                                        <FaCircle />
                                        <p>Good</p>
                                    </div>
                                </li>
                                <li className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item'>
                                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item-key'>Safe</div>
                                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item-value inadequate'>
                                        <FaCircle />
                                        <p>Inadequate</p>
                                    </div>
                                </li>
                                <li className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item'>
                                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item-key'>Well-led</div>
                                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratings-body-list-item-value improvement'>
                                        <FaCircle />
                                        <p>Requires Improvement</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='admin-dashboard-elements_body-cqcInfo-details-ratingbar good'>
                        Overall Good
                    </div>
                    <div className='admin-dashboard-elements_body-cqcInfo-details-desc'>
                        <ul className='admin-dashboard-elements_body-cqcInfo-details-desc-list'>
                            <li className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item'>
                                <div className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item-key'>Local Authority</div>
                                <div className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item-value local-authority'>London</div>
                            </li>
                            <li className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item'>
                                <div className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item-key'>Last Inspection</div>
                                <div className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item-value last-inspection'>7 Jul 2024</div>
                            </li>
                            <li className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item'>
                                <div className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item-key'>Last Report</div>
                                <div className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item-value last-report'>4 Jul 2024</div>
                            </li>
                            <li className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item'>
                                <div className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item-key'>Reports</div>
                                <div className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item-value reports'>21</div>
                            </li>
                            <li className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item'>
                                <div className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item-key'>Registration Date</div>
                                <div className='admin-dashboard-elements_body-cqcInfo-details-desc-list-item-value registration-date'>2 Jul 2024</div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <div className='admin-dashboard-elements_body-topInfo'>
                <div className='admin-dashboard-elements_body-topInfo-courses'>
                    <div className='admin-dashboard-elements_body-topInfo-courses-title'>Top Courses</div>
                    <div className='admin-dashboard-elements_body-topInfo-courses-data'>
                        <ul className='admin-dashboard-elements_body-topInfo-courses-data-list'>
                            {dashboardData.top_courses.map((data, index) => {
                            return <li className='admin-dashboard-elements_body-topInfo-courses-data-list-item'>
                                <div className='admin-dashboard-elements_body-topInfo-courses-data-list-item-icon firstItem'>D</div>
                                <div className='admin-dashboard-elements_body-topInfo-courses-data-list-item-title'>{data.course__name}</div>
                                <div className='admin-dashboard-elements_body-topInfo-courses-data-list-item-info'>{data.count}</div>
                            </li>
                            })}
                        </ul>
                    </div>
                </div>
                <div className='admin-dashboard-elements_body-topInfo-trainees'>
                    <div className='admin-dashboard-elements_body-topInfo-trainees-title'>Top Trainees</div>
                        <div className='admin-dashboard-elements_body-topInfo-trainees-data'>
                            <ul className='admin-dashboard-elements_body-topInfo-trainees-data-list'>
                                {dashboardData.top_trainees.map((data, index) => {
                                   return <li className='admin-dashboard-elements_body-topInfo-trainees-data-list-item'>
                                        <div className='admin-dashboard-elements_body-topInfo-trainees-data-list-item-icon firstItem'>LS</div>
                                        <div className='admin-dashboard-elements_body-topInfo-trainees-data-list-item-title'>{data.trainee_name}</div>
                                        <div className='admin-dashboard-elements_body-topInfo-trainees-data-list-item-info'>
                                            <span className='admin-dashboard-elements_body-topInfo-trainees-data-list-item-info-count'>{data.course_completion_count}</span>
                                            <span className='admin-dashboard-elements_body-topInfo-trainees-data-list-item-info-desc'>Courses</span>
                                        </div>
                                    </li>
                                })}
                            </ul>
                        </div>
                </div>
            </div>
            <div className='admin-dashboard-elements_body-progressInfo'>
                <div className='admin-dashboard-elements_body-progressInfo-title'>Course Progress</div>
                <div className='admin-dashboard-elements_body-progressInfo-data'>
                    <div className='admin-dashboard-elements_body-progressInfo-data-container'>
                        <div className='admin-dashboard-elements_body-progressInfo-data-container-title'>In Progress</div>
                        <div className='admin-dashboard-elements_body-progressInfo-data-container-bar'>
                            <CourseProgress completed='52' bgcolor="#DDE0E3" indicator='#16B2BB' />
                        </div>
                        <div className='admin-dashboard-elements_body-progressInfo-data-container-completed'>
                            <span className='admin-dashboard-elements_body-progressInfo-data-container-completed-percent'>52%</span>
                            <span className='admin-dashboard-elements_body-progressInfo-data-container-completed-count'>104 User</span>
                        </div>
                    </div>
                    <div className='admin-dashboard-elements_body-progressInfo-data-container'>
                        <div className='admin-dashboard-elements_body-progressInfo-data-container-title'>Completed</div>
                        <div className='admin-dashboard-elements_body-progressInfo-data-container-bar'>
                            <CourseProgress completed='27' bgcolor="#DDE0E3" indicator='#067D27' />
                        </div>
                        <div className='admin-dashboard-elements_body-progressInfo-data-container-completed'>
                            <span className='admin-dashboard-elements_body-progressInfo-data-container-completed-percent'>27%</span>
                            <span className='admin-dashboard-elements_body-progressInfo-data-container-completed-count'>104 User</span>
                        </div>
                    </div>
                    <div className='admin-dashboard-elements_body-progressInfo-data-container'>
                        <div className='admin-dashboard-elements_body-progressInfo-data-container-title'>Pending</div>
                        <div className='admin-dashboard-elements_body-progressInfo-data-container-bar'>
                            <CourseProgress completed="16" bgcolor="#DDE0E3" indicator='#FDB01B' />
                        </div>
                        <div className='admin-dashboard-elements_body-progressInfo-data-container-completed'>
                            <span className='admin-dashboard-elements_body-progressInfo-data-container-completed-percent'>16%</span>
                            <span className='admin-dashboard-elements_body-progressInfo-data-container-completed-count'>104 User</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>}
    </>
  )
}

export default AdminDashboardElements
