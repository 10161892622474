import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";
import toast from "react-hot-toast";
import "./PricingPlanSelector.scss";
import {
  setPricingPlan,
  getPricingPlanDetails,
} from "../services/api.services";
import SwitchToggle from "../components/SwitchToggle";
import carelearnerLogo from "../../images/certifications/carelearner_logo.png";
import cpdRegistered from "../../images/certifications/cpd_registered.png";
import cpdProvider from "../../images/certifications/cpd_provider.png";
import icoCertificate from "../../images/certifications/ico_certification.png";
import pentafoldLogo from "../../images/certifications/pentafold_logo.png";
import isoCertificate from "../../images/certifications/iso_certification.png";
import cyberEssetialsCertificate from "../../images/certifications/cyber_essentials_certification.png";
import stripeWatermark from "../../images/certifications/stripe_watermark.png";
import { FaEye } from "react-icons/fa";
import PopupModal from "../components/PopupModal";

const PricingPlanSelector = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [pricingPlanDetailsData, setPricingPlanDetailsData] = useState([]);
  const [filteredPricingPlanDetailsData, setFilteredPricingPlanDetailsData] =
    useState([]);

  let header = {
    Authorization: "Token " + state.token,
  };

  const sortDataByPosition = (data) => {
    return data.sort((a, b) => {
      return parseInt(a.data.position, 10) - parseInt(b.data.position, 10);
    });
  };

  useEffect(() => {
    const fetchPricingPlanDetails = async () => {
      try {
        const response = await getPricingPlanDetails(header);
        if (response.status === 200) {
          const sortedData = sortDataByPosition(response.data.results);
          setPricingPlanDetailsData(sortedData);
          // Filter data to get one entry for each unique position ("1", "2", "3")
          const filteredData = [];
          const positionsSeen = new Set();
          sortedData.forEach((planData) => {
            if (!positionsSeen.has(planData.data.position)) {
              filteredData.push(planData);
              positionsSeen.add(planData.data.position);
            }
          });
          setFilteredPricingPlanDetailsData(filteredData);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching pricing plan details:", error);
      }
    };

    fetchPricingPlanDetails();
  }, []);

  const handleButtonClick = (billingType, id, billingCycle) => {
    if (billingType === "subscription") {
      toast.success("Opted " + billingCycle + " Subscription");
      navigate("/stripe_gateway", {
        state: { planId: id },
      });
    } else if (billingType === "one_off") {
      window.open("https://www.carelearner.co.uk/book", "_blank");
    } else {
      handleStarterPlan(id);
    }
  };

  const handleStarterPlan = (planId) => {
    // toast.success("Opted Starter Plan")
    invokeSetPricingPlan(planId);
  };

  const invokeSetPricingPlan = async (planId) => {
    try {
      const response = await setPricingPlan(header, planId);
      if (response.status == 200) {
        toast.success("Pricing plan updated successfully");
        dispatch({
          type: "UPDATE_PRICING_PLAN",
          payload: {
            is_organisation_pricing_plan: true,
          },
        });
        navigate("/admin/");
      } else {
        toast.error("Something went wrong, Please Login and try again!!");
      }
    } catch (error) {
      console.error("Error setting Pricing Plan:", error);
    }
  };

  const switchDataForPopularPlan = (billingCycle) => {
    const updatedData = [...filteredPricingPlanDetailsData];

    const popularPlanIndex = updatedData.findIndex(
      (planData) =>
        planData.data.position === "2" &&
        planData.data.is_popular === true &&
        planData.billing_cycle === billingCycle
    );

    if (popularPlanIndex) {
      const otherPlanIndex = pricingPlanDetailsData.findIndex(
        (planData, index) =>
          planData.data.position === "2" &&
          planData.data.is_popular === true &&
          planData.billing_cycle !== billingCycle
      );
      console.log(
        "pricingPlanDetailsData[otherPlanIndex]:",
        pricingPlanDetailsData[otherPlanIndex]
      );
      console.log(
        "beforeUpdate:",
        updatedData[popularPlanIndex],
        pricingPlanDetailsData[otherPlanIndex]
      );
      if (otherPlanIndex !== -1) {
        updatedData[popularPlanIndex] = pricingPlanDetailsData[otherPlanIndex];
        setFilteredPricingPlanDetailsData(updatedData);
      }
    }
  };

  return (
    <div className="pricing_plan_selector">
      <PricingPlanSelectorHeader />
      <div className="pricing_plan_selector_content">
        <div className="pricing_plan_selector_content_title">
          <div className="pricing_plan_selector_content_title_text">
            Choose Your Plan
          </div>
          <div className="pricing_plan_selector_content_title_subtext">
            CareLearner believes in affordable and accessible training and
            compliance. Choose from three plans - <br/> Essentials, Advanced, and
            Enterprise - tailored to suit your budget and needs.
          </div>
        </div>
        <div className="pricing_plan_selector_content_body">
          <div className="pricing_plan_selector_content_body_container">
            {filteredPricingPlanDetailsData.map((planData, index) => (
              <div
                className="pricing_plan_selector_content_body_container_card"
                key={index}
              >
                <div className="pricing_plan_selector_content_body_container_card_top">
                  <div className="pricing_plan_selector_content_body_container_card_top_plan_name">
                    {planData.data.is_popular && (
                      <div className="pricing_plan_selector_content_body_container_card_top_plan_name_ribbon" />
                    )}
                    {planData.data.card_data.pricing_plan_name}
                  </div>

                  <div className="pricing_plan_selector_content_body_container_card_top_text">
                    {planData.data.card_data.pricing_plan_name ===
                      "Essential" && (
                      <div className="pricing_plan_selector_content_body_container_card_top_text_essential">
                        As Low as
                      </div>
                    )}
                    {planData.data.card_data.pricing_plan_name ===
                    "Enterprise" ? (
                      <div className="pricing_plan_selector_content_body_container_card_top_text_price">
                        {planData.data.card_data.cost_data}
                      </div>
                    ) : (
                      <>
                        <div className="pricing_plan_selector_content_body_container_card_top_text_icon">
                          £
                        </div>
                        <div className="pricing_plan_selector_content_body_container_card_top_text_price">
                          {planData.data.card_data.cost_data}
                        </div>
                        <div className="pricing_plan_selector_content_body_container_card_top_text_month">
                          /Month
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="pricing_plan_selector_content_body_container_card_middle">
                  <div className="pricing_plan_selector_content_body_container_card_middle_title">
                    {planData.data.card_data.card_title}
                  </div>
                  <div className="pricing_plan_selector_content_body_container_card_middle_body">
                    {planData.data.card_data.card_bullets.map(
                      (bullet, bulletIndex) => (
                        <div
                          className="pricing_plan_selector_content_body_container_card_middle_body_item"
                          key={bulletIndex}
                        >
                          <div className="pricing_plan_selector_content_body_container_card_middle_body_item_icon">
                            ✔
                          </div>
                          <div className="pricing_plan_selector_content_body_container_card_middle_body_item_text">
                            {bullet}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {planData.data.position === "2" &&
                    planData.data.is_popular && (
                      <SwitchToggle
                        billingCycle={planData.billing_cycle}
                        switchDataForPopularPlan={switchDataForPopularPlan}
                      />
                    )}
                </div>

                <div className="pricing_plan_selector_content_body_container_card_bottom">
                  <div
                    className="pricing_plan_selector_content_body_container_card_bottom_button"
                    onClick={() =>
                      handleButtonClick(
                        planData.billing_type,
                        planData.id,
                        planData.billing_cycle
                      )
                    }
                  >
                    Choose Plan
                  </div>
                  <div className="pricing_plan_selector_content_body_container_card_bottom_detailed_view">
                    <FaEye />
                    <div className="pricing_plan_selector_content_body_container_card_bottom_detailed_view_text">
                      View Details
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pricing_plan_selector_content_body_bottom">
            <img src={stripeWatermark} alt="powered_by_stripe" />
          </div>
        </div>
      </div>
      <PricingPlanSelectorFooter />
    </div>
  );
};

export default PricingPlanSelector;

const PricingPlanSelectorHeader = () => (
  <div className="pricing_plan_selector_header">
    <div className="pricing_plan_selector_header_links">
      <img alt="carelearner" src={carelearnerLogo} />
      <div className="pricing_plan_selector_header_links_help effect">Help</div>
      <div className="pricing_plan_selector_header_links_contact effect">
        Contact
      </div>
    </div>
    <div className="pricing_plan_selector_header_certificates">
      <img alt="cpd_approved_provider" src={cpdProvider} />
      <img alt="ico_certification" src={icoCertificate} />
      <img alt="cpd_registered" src={cpdRegistered} />
    </div>
  </div>
);

const PricingPlanSelectorFooter = () => (
  <div className="pricing_plan_selector_footer">
    <div className="pricing_plan_selector_footer_certifications">
      <div className="pricing_plan_selector_footer_certifications_images">
        <div className="pricing_plan_selector_footer_certifications_images_pentafold_logo">
          <span>Powered By</span>
          <img src={pentafoldLogo} alt="pentafold_logo" />
        </div>

        <img
          src={isoCertificate}
          alt="iso_certification"
          className="pricing_plan_selector_footer_certifications_images_iso_certification"
        />
        <img
          src={cyberEssetialsCertificate}
          alt="cyber_essentials"
          className="pricing_plan_selector_footer_certifications_images_cyber_essentials"
        />
      </div>
      <div className="pricing_plan_selector_footer_certifications_info">
        <div className="pricing_plan_selector_footer_certifications_info_item">
          <span className="pricing_plan_selector_footer_certifications_info_item_key">
            Reg No.
          </span>{" "}
          <span>:</span>
          <span className="pricing_plan_selector_footer_certifications_info_item_value">
            13960104
          </span>
        </div>
        <div className="pricing_plan_selector_footer_certifications_info_item">
          <span className="pricing_plan_selector_footer_certifications_info_item_key">
            VAT No.
          </span>{" "}
          <span>:</span>
          <span className="pricing_plan_selector_footer_certifications_info_item_value">
            446678842
          </span>
        </div>
      </div>
    </div>
    <div className="pricing_plan_selector_footer_links">
      <div className="pricing_plan_selector_footer_links_catalogue">
        Catalogue
      </div>
      <div className="pricing_plan_selector_footer_links_userguide">
        User Guide
      </div>
      <div className="pricing_plan_selector_footer_links_adminguide">
        Admin Guide
      </div>
    </div>
  </div>
);
