import React, { useState, useContext } from "react";
import { FaCaretDown, FaCaretUp, FaTimes } from "react-icons/fa";
import { createAdminModule } from "../services/api.services";
import toast from "react-hot-toast";
import "./CreateModulePopup.scss";

const CreateModulePopup = ({ header, allCourseData, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState("Select Course");
  const [moduleData, setModuleData] = useState({
    name: "",
    position: "",
    course: "",
    icon: null,
  });

  const toggleModal = () => {
    setSelectedCourse("Select Course");
    setIsDropdownOpen(!isDropdownOpen);
    setIsOpen(!isOpen);
    setModuleData({
      name: "",
      position: "",
      course: "",
      icon: null,
    })
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectCourse = (course) => {
    setSelectedCourse(course);
    setIsDropdownOpen(false);
    const selectedCourseData = allCourseData.find((item) => item.name === course);
    if (selectedCourseData) {
      setModuleData({ ...moduleData, course: selectedCourseData.id });
    }
  };
  

  function uploadFile(event) {
    const fileName = event.target.files[0].name;
    document.getElementById("file-name").textContent = fileName;
    setModuleData({ ...moduleData, icon: event.target.files[0] });
  };

  const handleSubmit = async () => {
    // Validation
    if (
      moduleData.name.trim() === "" ||
      moduleData.position === "" ||
      moduleData.course === ""
    ) {
      toast.error("All fields are required");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", moduleData.name);
      formData.append("position", moduleData.position);
      formData.append("course", moduleData.course);
      if (moduleData.icon) {
        formData.append("icon", moduleData.icon);
      }

      const response = await createAdminModule(header, formData);

      if (response.status === 200 && response.data.isSuccess) {
        toast.success("Module created successfully");
        toggleModal();
        setModuleData({
          name: "",
          position: "",
          course: "",
          icon: null,
        })
        onSuccess(); 
      } else {
        toast.error("Failed to create module");
      }
    } catch (error) {
      toast.error("Error creating module");
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setModuleData({ ...moduleData, [id]: value });
  };

  return (
    <div>
      <button className="create_module-modal-button" onClick={toggleModal}>
        <span className="create_module-modal-button-icon">+</span>
        <span className="create_module-modal-button-text">Add Module</span>
      </button>
      

      {isOpen && (
        <div className="create_module-modal-overlay">
          <div className="create_module-modal-content">
            <div
              onClick={toggleModal}
              className="create_module-modal-content-close_button"
            >
              <FaTimes />
            </div>
            <div className="create_module-modal-content-title">
              Create Module
            </div>

            <label htmlFor="moduleName">Module Name</label>
            <input
              type="text"
              id="name"
              placeholder="eg: Module Name"
              value={moduleData.name}
              onChange={handleChange}
            />

            <label htmlFor="position">Position</label>
            <input
              type="number"
              id="position"
              placeholder="Position"
              value={moduleData.position}
              onChange={handleChange}
            />

            <label htmlFor="course">Course</label>
            <div className="dropdown-container">
              <div className="input-dropdown" onClick={toggleDropdown}>
                <span>{selectedCourse}</span>
                <span>{isDropdownOpen ? <FaCaretUp /> : <FaCaretDown />}</span>
              </div>
              {isDropdownOpen && (
                <div className="dropdown-list">
                  {allCourseData.map((course) => (
                    <div
                      className="dropdown-item"
                      key={course.id}
                      onClick={() => selectCourse(course.name)}
                    >
                      {course.name}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <label htmlFor="moduleThumbnail">Module Icon</label>
            <div className="inputfile-box">
              <input
                type="file"
                id="moduleThumbnail"
                className="inputfile"
                onChange={uploadFile}
                placeholder="Choose File"
              />
              <label htmlFor="moduleThumbnail">
                <span id="file-name" className="file-box"></span>
                <span className="file-button">Browse</span>
              </label>
            </div>

            <button
              className="create_module-modal-content-submit"
              onClick={handleSubmit}
            >
              Create Module
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateModulePopup;
