import { React, useState, useEffect } from "react";
import "./RadialProgressBar.scss";

const RadialProgressBar = ({ progressId, initialValue, isComplete }) => {
  const [progressValue, setProgressValue] = useState(0);
  const progressColor = isComplete ? "#15c215" : "#fcb43a";
  useEffect(() => {
    // Set progress value using the progressId or initialValue
    // Example: Fetch progress for this particular chapter using its ID
    // Update the progressValue accordingly
    // setProgressValue(updatedProgressValue);
    let progressStart = 0
    let speed = 20
    let progress = setInterval(() => {
      if(initialValue !== null){
        if(initialValue == 0){
          setProgressValue(progressStart)
          clearInterval(progress);
        }
        else{
          progressStart++
          setProgressValue(progressStart)
          if(progressStart == initialValue) {
            clearInterval(progress);
          }
        }
      }
    }, speed)
  }, [progressId, initialValue]);
 

  return (
    <div
      className="progress-bar"
      style={{ "--progress-value": progressValue, "--progress-color": progressColor }}
    >
      <progress
        min="0"
        max="100"
        style={{ visibility: "hidden", height: 0, width: 0 }}
      ></progress>
      <span>{progressValue}%</span>
      
    </div>
  );
};

export default RadialProgressBar;
