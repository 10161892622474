import React, { useState, useContext, useEffect } from "react";
import "./MockTest.scss";
import { getMockTestsData } from '../services/api.services';
import toast from 'react-hot-toast';
import { AuthContext } from '../App';
import MockTestQuiz from '../components/MockTestQuiz';

const MockTest = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [quizData, setQuizData] = useState([]);
  const [selectedMockTest, setSelectedMockTest] = useState(null);
  var currentCourse = localStorage.currentCourseId

  useEffect(() => {
    async function fetchQuizData() {
      try {
        const header = {
          'Authorization': "Token " + state.token
        };

        const response = await getMockTestsData(header, currentCourse);
        if (response.data.isSuccess) {
          setQuizData(response.data.dataInfo);
        } else {
          toast.error("Failed to fetch quiz data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching quiz data.");
      }
    }

    fetchQuizData();
  }, [currentCourse, state.token]);


  const handleMockTestClick = (mockTest) => {
    setSelectedMockTest(mockTest);
  }


  if (selectedMockTest) {
    
    return (
      <MockTestQuiz
        mockTestData={selectedMockTest}
        onBack={() => setSelectedMockTest(null)}
      />
    );
  } else {
    
    return (
      <div className="mock_test">
        <div className="mock_test-title">Mock Tests</div>
        {quizData.map((quiz) => (
          <div
            className="mock_test-body"
            key={quiz.id}
            onClick={() => handleMockTestClick(quiz)}
          >
            <div className="mock_test-body-content">
              <div className="mock_test-body-content-details">
                <div className="mock_test-body-content-details-title">
                  {quiz.name}
                </div>
                <div className="mock_test-body-content-details-info">
                  <div className="mock_test-body-content-details-info-left">
                    <div className="mock_test-body-content-details-info-left-top">
                      <span>Attempts</span>: <span>{quiz.Attempts}</span>
                    </div>
                    <div className="mock_test-body-content-details-info-left-bottom">
                      <span>Last Attempt</span>:{" "}
                      <span>{quiz["Last Attempt"]}</span>
                    </div>
                  </div>

                  <div className="mock_test-body-content-details-info-right">
                    <div className="mock_test-body-content-details-info-right-top">
                      <span>Number of Questions</span>:{" "}
                      <span>{quiz["Number of Questions"]}</span>
                    </div>
                    <div className="mock_test-body-content-details-info-right-bottom">
                      <span>Total Marks</span>:{" "}
                      <span>{quiz["Total Marks"]}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
};

export default MockTest;