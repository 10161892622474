import React, { useState } from "react";
import toast from "react-hot-toast";
import "./EditProfilePicturePopup.scss";
import { FaRegImages  } from "react-icons/fa";
import avatar from '../images/icons/user_avatar.png'

const EditProfilePicturePopup = ({
  userAvatar,
  updateFunction,
  header,
  onAvatarUpdate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [error, setError] = useState("");
  const togglePopup = () => {
    setIsOpen(!isOpen);
    setError("")
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      setError("Please select an image file.");
      toast.error("Please select an image file.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("avatar", selectedFile);
      togglePopup();
      toast.loading("Updating user avatar..")
      const response = await updateFunction(header, formData);
      if (response.data.isSuccess) {
        toast.remove()
        togglePopup();
        setSelectedFile(null);
        toast.success("Profile picture updated successfully.");
        onAvatarUpdate(response.data.dataInfo.avatar);
      } else {
        toast.remove()
        setError("Please select an image file.");
        toast.error("Please select an image file.");
      }
    } catch (error) {
      setError("Error uploading avatar:", error);
      toast.error("Error uploading avatar.");
    }
  };

  const cancelEdit = () => {
    setSelectedFile({});
    setError("");
    togglePopup();
  };

  return (
    <div>
      <div className="edit-profile-picture-popup-link" onClick={togglePopup}>
        <img src={userAvatar ? userAvatar : avatar} alt="avatar" />
        <div className="edit-profile-picture-popup-link-overlay">
            <FaRegImages className="edit-profile-picture-popup-link-overlay-icon-fa-user" />  
        </div>
      </div>

      {isOpen && (
        <div className="edit-profile-picture-popup-overlay popup-open">
          <div className="edit-profile-picture-popup-body popup-open">
            <div className="edit-profile-picture-popup-body-title">
              Edit Profile Picture
            </div>
            <div className="edit-profile-picture-popup-body-picture">
              <img src={avatar} alt="profile_picture" />
            </div>
            <input type="file" name="avatar" onChange={handleFileChange} />
            {error && (
              <div className="edit-profile-picture-popup-error">{error}</div>
            )}
            <button
              className="edit-profile-picture-popup-body-submit"
              onClick={handleSubmit}
            >
              Update
            </button>
            <button
              className="edit-profile-picture-popup-body-close"
              onClick={cancelEdit}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfilePicturePopup;
