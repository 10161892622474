import React, { useState, useContext } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../App";
import { FaTimes } from "react-icons/fa";
import { createTraineeData } from "../services/api.services";
import "./AddTraineePopup.scss";


const AddTraineePopup = ({ onSuccess }) => {
  const { state } = useContext(AuthContext);
  const [error, setError] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [traineeData, setTraineeData] = useState({
    firstname: "",
    lastname: "",
    startdate: "",
    email: "",
    phone: "",
    password: "",
  });

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setTraineeData({
      firstname: "",
      lastname: "",
      startdate: "",
      email: "",
      phone: "",
      password: "",
    });
    setError({});
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,}$/;
    return emailRegex.test(email);
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z]+$/;
    return nameRegex.test(name);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{7,15}$/;
    return phoneRegex.test(phone);
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};

    if (!traineeData.firstname.trim()) {
      formIsValid = false;
      errors["firstname"] = "First name is required";
    } else if (!validateName(traineeData.firstname)) {
      formIsValid = false;
      errors["firstname"] = "First name must contain only letters";
    }

    if (!traineeData.lastname.trim()) {
      formIsValid = false;
      errors["lastname"] = "Last name is required";
    } else if (!validateName(traineeData.lastname)) {
      formIsValid = false;
      errors["lastname"] = "Last name must contain only letters";
    }

    if (!traineeData.startdate.trim()) {
      formIsValid = false;
      errors["startdate"] = "Start date is required";
    }

    if (!traineeData.email.trim()) {
      formIsValid = false;
      errors["email"] = "Email is required";
    } else if (!validateEmail(traineeData.email)) {
      formIsValid = false;
      errors["email"] = "Email is not valid";
    }

    if (!traineeData.phone.trim()) {
      formIsValid = false;
      errors["phone"] = "Phone number is required";
    } else if (!validatePhone(traineeData.phone)) {
      formIsValid = false;
      errors["phone"] = "Invalid phone number";
    }

    if (!traineeData.password.trim()) {
      formIsValid = false;
      errors["password"] = "Password is required";
    } else if (traineeData.password.length < 6) {
      formIsValid = false;
      errors["password"] = "Password must be at least 6 characters long";
    }

    setError(errors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      toast.error("Invalid Data!!")
      return;
    }

    try {
      const formData = new FormData();
      formData.append("firstname", traineeData.firstname);
      formData.append("lastname", traineeData.lastname);
      formData.append("startdate", traineeData.startdate);
      formData.append("email", traineeData.email);
      formData.append("phone", traineeData.phone);
      formData.append("password", traineeData.password);

      const response = await createTraineeData(
        { Authorization: `Token ${state.token}` },
        formData
      );
      if (response.data.isSuccess) {
        toast.success("Trainee created successfully");
        onSuccess();
        setTraineeData({
          firstname: "",
          lastname: "",
          startdate: "",
          email: "",
          phone: "",
          password: "",
        });
        toggleModal();
      } else {
        if (response.data.dataInfo && response.data.dataInfo.email) {
          toast.error(response.data.dataInfo.email[0]);
        } else {
          toast.error(response.data.message || "Failed to add trainee");
        }
      }
    } catch (error) {
      toast.error("Error creating trainee");
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setTraineeData({ ...traineeData, [id]: value });

    let newError = { ...error };
    switch (id) {
      case "firstname":
        if (!value.trim()) {
          newError["firstname"] = "First name is required";
        } else if (!validateName(value)) {
          newError["firstname"] = "First name must contain only letters";
        } else {
          delete newError["firstname"];
        }
        break;
      case "lastname":
        if (!value.trim()) {
          newError["lastname"] = "Last name is required";
        } else if (!validateName(value)) {
          newError["lastname"] = "Last name must contain only letters";
        } else {
          delete newError["lastname"];
        }
        break;
      case "startdate":
        if (!value.trim()) {
          newError["startdate"] = "Start date is required";
        } else {
          delete newError["startdate"];
        }
        break;
      case "email":
        if (!value.trim()) {
          newError["email"] = "Email is required";
        } else if (!validateEmail(value)) {
          newError["email"] = "Email is not valid";
        } else {
          delete newError["email"];
        }
        break;
      case "phone":
        if (!value.trim()) {
          newError["phone"] = "Phone number is required";
        } else if (!validatePhone(value)) {
          newError["phone"] = "Phone number must contain only digits";
        } else {
          delete newError["phone"];
        }
        break;
      case "password":
        if (!value.trim()) {
          newError["password"] = "Password is required";
        } else if (value.length < 6) {
          newError["password"] = "Password must be at least 6 characters long";
        } else {
          delete newError["password"];
        }
        break;
      default:
        break;
    }
    setError(newError);
  };

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <div>
      <button className="add_trainee_modal-button" onClick={toggleModal}>
        <span className="add-trainee-modal-button-icon">+</span>
        <span className="add-trainee-modal-button-text">Add Trainee</span>
      </button>

      {isOpen && (
        <div className="add_trainee_modal-overlay">
          <div className="add_trainee_modal-content">
            <div
              onClick={toggleModal}
              className="add_trainee_modal-content-close_button"
            >
              <FaTimes />
            </div>
            <div className="add_trainee_modal-content-title">Add Trainee</div>

            <form onSubmit={handleSubmit}>
              <label htmlFor="firstname">
                First Name
                {error.firstname && (
                  <span className="error">({error.firstname})</span>
                )}
              </label>
              <input
                type="text"
                id="firstname"
                value={traineeData.firstname}
                onChange={handleChange}
              />

              <label htmlFor="lastname">
                Last Name
                {error.lastname && (
                  <span className="error">({error.lastname})</span>
                )}
              </label>
              <input
                type="text"
                id="lastname"
                value={traineeData.lastname}
                onChange={handleChange}
              />

              <label htmlFor="startdate">
                Start Date
                {error.startdate && (
                  <span className="error">({error.startdate})</span>
                )}
              </label>
              <input
                type="date"
                id="startdate"
                name="startdate"
                value={traineeData.startdate}
                min={currentDate}
                max="2030-12-31"
                onChange={handleChange}
              />

              <label htmlFor="email">
                Email
                {error.email && <span className="error">({error.email})</span>}
              </label>
              <input
                type="email"
                id="email"
                value={traineeData.email}
                onChange={handleChange}
              />

              <label htmlFor="phone">
                Mobile Number
                {error.phone && <span className="error">({error.phone})</span>}
              </label>
              <input
                type="text"
                id="phone"
                value={traineeData.phone}
                onChange={handleChange}
              />

              <label htmlFor="password">
                Temporary Password
                {error.password && (
                  <span className="error">({error.password})</span>
                )}
              </label>
              <input
                type="text"
                id="password"
                value={traineeData.password}
                onChange={handleChange}
              />

              <div className="add_trainee_modal-content-bottom">
                <div className="add_trainee_modal-content-bottom-buttons">
                  <button
                    className="add_trainee_modal-content-bottom-buttons-submit"
                    type="submit"
                  >
                    Add Trainee
                  </button>
                  <button
                    onClick={toggleModal}
                    className="add_trainee_modal-content-bottom-buttons-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddTraineePopup;
