import React from 'react';
import './AppPagination.scss';

const AppPagination = ({  setCurrentPage, currentPage, recordsPerPage, data_count }) => {
    const pages = Math.ceil(data_count / recordsPerPage);
    const numbers = [...Array(pages+1).keys()].slice(1);

    const handlePrevPage = () => {
        if(currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    const handleNextPage = () => {
        if(currentPage !== pages) {
            setCurrentPage(currentPage + 1)
        }
    }
    const handleChangePage = (number) => {
        setCurrentPage(number)
    }

  return (
    <div className='app-pagination'>
        <nav className='app-pagination_nav'>
            <ul className='app-pagination_nav-list'>
                <li className='app-pagination_nav-list-item'>
                    <button className='app-pagination_nav-list-item-link' onClick={handlePrevPage}>Prev</button>
                </li>
                {numbers.map((number, index)=>{
                    return <li className={`app-pagination_nav-list-item ${currentPage === number? 'active' : ''}`} key={index}>
                        <button className='app-pagination_nav-list-item-link' onClick={() => handleChangePage(number)}>{number}</button>
                    </li>
                })}
                <li className='app-pagination_nav-list-item'>
                    <button className='app-pagination_nav-list-item-link' onClick={handleNextPage}>Next</button>
                </li>
            </ul>
        </nav>
    </div>
  )
}

export default AppPagination
