import React, { useState, useContext } from "react";
import "./ChangePasswordPopup.scss";
import toast from 'react-hot-toast';
import { changePassword } from "../services/api.services";
import { AuthContext } from '../App';

const ChangePasswordPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {state, dispatch } = useContext(AuthContext);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  
  let header = {
    'Authorization': "Token "+ state.token
  }

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const resetForm = () => {
    setNewPassword("");
    setConfirmNewPassword("");
    setError("");
  };

  const handleSubmit = async () => {
    if (newPassword !== confirmNewPassword) {
      setError("Passwords don't match.");
      return;
    }
    const res = await changePassword(header, newPassword);

    if (res.data.isSuccess) {
      togglePopup(); 
      resetForm();
      toast.success("Password Updated");
    } else {
      setError("Password change failed. Please try again.");
    }
  };

  return (
    <div>
      <div className="change-password-popup-link" onClick={togglePopup}>
        Change Password
      </div>

      {isOpen && (
        <div className="change-password-popup-overlay popup-open">
          <div className="change-password-popup-body popup-open">
            <div className="change-password-popup-body-title">Change Password</div>
            <input
          type="password"
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required 
        />

        <input
          type="password"
          placeholder="Re-enter new password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          required 
        />
            {error && <div className="change-password-popup-error">{error}</div>}
            
            <button className="change-password-popup-body-submit" onClick={handleSubmit}>
              Update
            </button>
            <button className="change-password-popup-body-close" onClick={() => { togglePopup(); resetForm(); }}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePasswordPopup;