import React, { useState } from 'react';
import './SwitchToggle.scss';

const SwitchToggle = ({billingCycle,switchDataForPopularPlan}) => {
  const [selectedPlan, setSelectedPlan] = useState('Monthly');

  const handleSwitch = (event) => {
    setSelectedPlan(event.target.value);
    switchDataForPopularPlan(billingCycle)
  };

  return (
    <div className="container">
      <div className="switches-container">
        <input
          type="radio"
          id="switchMonthly"
          name="switchPlan"
          value="Monthly"
          checked={selectedPlan === 'Monthly'}
          onChange={handleSwitch}
        />
        <input
          type="radio"
          id="switchYearly"
          name="switchPlan"
          value="Yearly"
          checked={selectedPlan === 'Yearly'}
          onChange={handleSwitch}
        />
        <label htmlFor="switchMonthly">Monthly</label>
        <label htmlFor="switchYearly">Yearly</label>
        <div className="switch-wrapper">
          <div className="switch">
            <div>Monthly</div>
            <div>Yearly</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchToggle;
