import React, { useState } from 'react';
import './AdminCustomCheckbox.scss';

const AdminCustomCheckbox = ({selectAll, data_id, setCheckedData, checkedData, responseData}) => {
    const [checked, setChecked] = useState(false)

    const handleSingleBoxCheck = (data) => {
      if(!checkedData.includes(data)){
        setCheckedData([...checkedData, data])
      }
      else{
        setCheckedData(checkedData.filter((id) => id !== data))
      }
    }
    const handleAllBoxCheck = () => {
      setChecked(!checked);
      if (checkedData.length === responseData.length) {
        setCheckedData([]);
      } else {
        setCheckedData(responseData.map((response) => response.id));
      }      
    }

  return (
    <div className='custom-checkbox'>
      <input type='checkbox' checked={selectAll ? checked : checkedData.includes(data_id)} onChange={selectAll ? handleAllBoxCheck : () => handleSingleBoxCheck(data_id)} />
      <span className="checkmark"></span>
    </div>
  )
}

export default AdminCustomCheckbox
