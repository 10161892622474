import React from "react";
import "./AdminHeader.scss";
import logo from "../../images/icons/logo.png";
import tabLogo from "../../images/icons/mobile_logo.png";
import user_avatar from "../../images/icons/user_avatar.png";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";

const AdminHeader = ({ toggleSidebar, creditBalance, isSubscription }) => {
  return (
    <div className="header">
      <FaBars className="header-hambuger" onClick={() => toggleSidebar()} />
      <div className="header-logo">
        <div className="header-logo-img">
          <img src={logo} alt="logo" />
        </div>
        {/* <div className="header-logo-tab">
          <img src={tabLogo} alt="logo" />
        </div> */}
      </div>
      <div className="header-user">
        {!isSubscription && (
          <div className="header-user-credits">Credits : {creditBalance}</div>
        )}
        <div className="header-user-avatar">
          <div className="header-user-avatar-name">Admin</div>
          <div className="header-user-avatar-image">
            <img src={user_avatar} alt="picture" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
