import React, { useState, useEffect, useContext } from "react";
import "./MockTestQuiz.scss";
import { FaArrowRight } from "react-icons/fa";
import toast from "react-hot-toast";
import { AuthContext } from "../App";
import {
  getMockTestQuestionsData,
  createMockTestAttempt,
} from "../services/api.services";
import MockTestResult from "./MockTestResult";

const MockTestQuiz = ({ mockTestData, onBack }) => {
  const [questionsData, setQuestionsData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showFinishButton, setShowFinishButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [quizFinished, setQuizFinished] = useState(false);
  const [resultData, setResultData] = useState(null);

  const { state } = useContext(AuthContext);

  useEffect(() => {
    async function fetchQuestionsData() {
      try {
        const header = {
          Authorization: "Token " + state.token,
        };

        const response = await getMockTestQuestionsData(
          header,
          mockTestData.id
        );

        if (response.data.isSuccess) {
          setQuestionsData(response.data.dataInfo);
        } else {
          toast.error("Failed to fetch questions data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching questions data:", error);
      }
    }

    fetchQuestionsData();

    if(mockTestData["Number of Questions"]===1) {
      if ((currentQuestion+1) === mockTestData["Number of Questions"]) {
        setShowFinishButton(true);
      }
    }
    
  }, [mockTestData.id, state.token]);

  const handleRadioButtonClick = (questionId, answerId) => {
    setSelectedAnswers({ ...selectedAnswers, [questionId]: answerId });
  };

  const handleNextButtonClick = () => {
    const nextQuestion = currentQuestion + 1;

    if (!selectedAnswers.hasOwnProperty(questionsData[currentQuestion].id)) {
      toast.error(
        "Please select an answer before moving to the next question."
      );
      return;
    }

    setCurrentQuestion(nextQuestion);

    if (nextQuestion === mockTestData["Number of Questions"] - 1) {
      setShowFinishButton(true);
    }

    const newProgressPercentage =
      ((nextQuestion + 1) / mockTestData["Number of Questions"]) * 100;
    setProgressPercentage(newProgressPercentage);
  };

  const handleFinishButtonClick = () => {
    if (!selectedAnswers.hasOwnProperty(questionsData[currentQuestion].id)) {
      toast.error("Please select an answer before finishing the quiz.");
      return;
    }

    const toastId = toast.loading("Submitting quiz...");

    let header = {
      Authorization: "Token " + state.token,
    };

    setIsLoading(true);

    createMockTestAttempt(header, mockTestData.id, selectedAnswers)
      .then((resp) => {
        toast.dismiss(toastId);
        if (resp.data.isSuccess) {
          setIsLoading(false);
          toast.success("Quiz Submitted!");
          setResultData(resp.data);
          setQuizFinished(true);
        } else {
          setIsLoading(false);
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        toast.dismiss(toastId);
        setIsLoading(false);
        toast.error("Something went wrong!");
      });
  };
  const questionsRemaining = mockTestData["Number of Questions"] - currentQuestion;
  return (
    <>
      {quizFinished ? (
        <MockTestResult dataInfo={resultData.dataInfo} onBack={onBack} />
      ) : (
        <div className="mock_test_quiz">
          <div className="mock_test_quiz-title">
            <div className="mock_test_quiz-title-text">{mockTestData.name}</div>
            <div className="mock_test_quiz-title-details">
              <div className="mock_test_quiz-title-details-left">
                <span style={{ fontWeight: "bold" }}>Questions Remaining</span>:{" "}
                <span>{questionsRemaining} / {mockTestData["Number of Questions"]}</span>
              </div>
              {/* <div className="mock_test_quiz-title-details-right">
                <span style={{ fontWeight: "bold" }}>Questions Skipped</span>:{" "}
                <span>0</span>
              </div> */}
            </div>
          </div>
          <div className="mock_test_quiz-body">
            {currentQuestion < questionsData.length && (
              <div className="mock_test_quiz-body-top">
                <div className="mock_test_quiz-body-top-module_name">
                  {questionsData[currentQuestion].module_name}
                </div>
                <div className="mock_test_quiz-body-top-question">
                  <span style={{ marginInlineEnd: "5px" }}>{`Q${
                    currentQuestion + 1
                  }:`}</span>
                  <span>{questionsData[currentQuestion].text}</span>
                </div>
                <div className="mock_test_quiz-body-top-answer">
                  {questionsData[currentQuestion].answers.map((answer) => (
                    <div
                      key={answer.id}
                      className="mock_test_quiz-body-top-answer-option"
                    >
                      <input
                        type="radio"
                        id={answer.id}
                        name={`question_${questionsData[currentQuestion].id}`}
                        value={answer.id}
                        checked={
                          selectedAnswers[questionsData[currentQuestion].id] ===
                          answer.id
                        }
                        onChange={() =>
                          handleRadioButtonClick(
                            questionsData[currentQuestion].id,
                            answer.id
                          )
                        }
                      />
                      <label htmlFor={answer.id}>{answer.text}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="mock_test_quiz-body-bottom">
              <div className="mock_test_quiz-body-bottom-progress">
                <div className="mock_test_quiz-body-bottom-progress-container">
                  <div
                    className="mock_test_quiz-body-bottom-progress-container-filler"
                    style={{ width: `${progressPercentage}%` }}
                  ></div>
                </div>
              </div>
              <div className="mock_test_quiz-body-bottom-button">
                {/* <button style={{ backgroundColor: "#656e80", color: "white" , borderColor:'#656e80'}}>
                  Skip
                </button> */}
                {showFinishButton ? (
                  <button onClick={handleFinishButtonClick}>Finish</button>
                ) : (
                  <button onClick={handleNextButtonClick}>
                    Next <FaArrowRight />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MockTestQuiz;
