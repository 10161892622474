import React, { useState, useEffect, useContext } from "react";
import "./AdminModules.scss";
import { AuthContext } from "../../App";
import toast from "react-hot-toast";
import CreateModulePopup from "../components/CreateModulePopup";
import DeleteModulePopup from "../components/DeleteModulePopup";
import EditModulePopup from "../components/EditModulePopup";
import { getAdminModuleData, getAllCourses } from "../services/api.services";
import { FaPencilAlt } from "react-icons/fa";

const AdminModules = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [moduleData, setModuleData] = useState([]);
  const [allCourseData, setAllCourseData] = useState([]);

  useEffect(() => {
    getModuleData();
    getCourseData();
  }, []);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getModuleData = async () => {
    try {
      const response = await getAdminModuleData(header);
      if (response.data.isSuccess) {
        setModuleData(response.data.dataInfo);
      } else {
        toast.error("Failed to fetch module data");
      }
    } catch (error) {
      toast.error("Error fetching module data: " + error.message);
    }
  };
  const getCourseData = async () => {
    try {
      const response = await getAllCourses(header);
      if (response.data.isSuccess) {
        setAllCourseData(response.data.dataInfo);
      } else {
        toast.error("Failed to fetch course data");
      }
    } catch (error) {
      toast.error("Error fetching course data: " + error.message);
    }
  };
  const refreshModuleData = () => {
    getModuleData();
  };
  return (
    <div className="admin-modules">
      <div className="admin-modules-title">
        <div className="admin-modules-title-text">Modules</div>
        <CreateModulePopup
          header={header}
          allCourseData={allCourseData}
          onSuccess={refreshModuleData}
        />
      </div>
      <div className="admin-modules-body">
        <div className="admin-modules-body-table">
          <div className="admin-modules-body-table-head">
            <div className="admin-modules-body-table-head-item">
              Module Name
            </div>
            <div className="admin-modules-body-table-head-item">Course</div>
            <div className="admin-modules-body-table-head-item">Chapters</div>
            <div className="admin-modules-body-table-head-item">Action</div>
          </div>
          <div className="admin-modules-body-table-body">
            {moduleData.map((data, index) => (
              <div className="admin-modules-body-table-body-row" key={index}>
                <div className="admin-modules-body-table-body-row-item">
                  <div className="admin-modules-body-table-body-row-item-img">
                    <img src="" alt="icon" />
                  </div>
                  <div className="admin-modules-body-table-body-row-item-text">
                    {data.name}
                  </div>
                </div>
                <div className="admin-modules-body-table-body-row-item">
                  {data.course_name}
                </div>
                <div className="admin-modules-body-table-body-row-item">
                  {data.chapter_count}
                </div>
                <div className="admin-modules-body-table-body-row-item">
                  <div className="admin-modules-body-table-body-row-item-icons">
                    <EditModulePopup
                      className="icon-pencil"
                      header={header}
                      allCourseData={allCourseData}
                      onSuccess={refreshModuleData}
                      currentModuleData={data}
                    />
                    <DeleteModulePopup
                      className="icon-trash"
                      header={header}
                      moduleId={data.id}
                      moduleName={data.name}
                      courseName={data.course_name}
                      onSuccess={refreshModuleData}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminModules;
