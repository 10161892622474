import React, { useState } from "react";
import { FaCaretDown, FaCaretUp, FaTimes, FaPencilAlt } from "react-icons/fa";
import { updateAdminModule } from "../services/api.services";
import toast from "react-hot-toast";
import "./EditModulePopup.scss"

const EditModulePopup = ({
  header,
  currentModuleData,
  allCourseData,
  onSuccess,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState(
    currentModuleData.course_name
  );
  const [updatedModuleData, setUpdatedModuleData] = useState({
    name: currentModuleData.name,
    course: currentModuleData.course_id,
    position: currentModuleData.position,
    icon: currentModuleData.icon,
  });

  const toggleModal = () => {
    setSelectedCourse(currentModuleData.course_name);
    setIsDropdownOpen(!isDropdownOpen);
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectCourse = (course_id, course_name) => {
    setSelectedCourse(course_name);
    setIsDropdownOpen(false);
    setUpdatedModuleData({
      ...updatedModuleData,
      course: course_id,
    });
  };

  function uploadFile(event) {
    const fileName = event.target.files[0].name;
    document.getElementById("file-name").textContent = fileName;
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUpdatedModuleData({
      ...updatedModuleData,
      [id]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await updateAdminModule(
        header,
        currentModuleData.id,
        updatedModuleData
      );
      if (response.status === 200 && response.data.isSuccess) {
        toast.success("Module updated successfully");
        onSuccess(); // Invoke the onSuccess prop function
      } else {
        toast.error("Failed to update module");
      }
    } catch (error) {
      toast.error("Error updating module: " + error.message);
    }
    toggleModal();
  };

  return (
    <div>
      <FaPencilAlt id="icon-pencil" onClick={toggleModal} />

      {isOpen && (
        <div className="edit_module-modal-overlay">
          <div className="edit_module-modal-content">
            <div
              onClick={toggleModal}
              className="edit_module-modal-content-close_button"
            >
              <FaTimes />
            </div>
            <div className="edit_module-modal-content-title">Edit Module</div>

            <label htmlFor="editName">Module Name</label>
            <input
              type="text"
              id="name"
              value={updatedModuleData.name}
              onChange={handleChange}
              placeholder="eg: OET"
            />
            <label htmlFor="position">Position</label>
            {/* <input type="number" id="position" placeholder="Position" /> */}
            <input
              type="number"
              id="position"
              value={updatedModuleData.position}
              onChange={handleChange}
              
            />
            <div className="dropdown-container">
              <div className="input-dropdown" onClick={toggleDropdown}>
                <span>{selectedCourse}</span>
                <span>{isDropdownOpen ? <FaCaretUp /> : <FaCaretDown />}</span>
              </div>
              {isDropdownOpen && (
                <div className="dropdown-list">
                  {allCourseData.map((data) => (
                    <div
                      className="dropdown-item"
                      key={data.id}
                      onClick={() => selectCourse(data.id, data.name)}
                    >
                      {data.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            <label htmlFor="moduleThumbnail">Module Icon</label>
            <div className="inputfile-box">
              <input
                type="file"
                id="moduleThumbnail"
                className="inputfile"
                onChange={uploadFile}
                placeholder="Choose File"
              />
              <label htmlFor="modulehumbnail">
                <span id="file-name" className="file-box"></span>
                <span className="file-button">Browse</span>
              </label>
            </div>

            <button
              className="edit_module-modal-content-submit"
              onClick={handleSubmit}
              style={{ width: "100%" }} // Inline style
            >
              Save Changes
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditModulePopup;
