import React from 'react';
import "../../node_modules/video-react/dist/video-react.css";
import { Player } from 'video-react';

const AppVideoPlayer = (contentURL) => {
  return (
    <div>
      <Player
        playsInline
        // poster=""
        src={contentURL.contentURL}
        />
    </div>
  )
}

export default AppVideoPlayer
