import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import "./StripeSetupSession.scss";
import { getStripeSession } from "../admin/services/api.services";
import { AuthContext } from "../App";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const StripeSetupSession = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [planId, setPlanId] = useState("");
  const { state, dispatch } = useContext(AuthContext);
  const location = useLocation();

  let header = {
    Authorization: "Token " + state.token,
  };

  useEffect(() => {
    const fetchStripeSession = async () => {
      const planId = location.state.planId;
      try {
        const response = await getStripeSession(header, planId);
        if (response) {
          setClientSecret(response.data.dataInfo.client_secret);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching credit details:", error);
      }
    };

    fetchStripeSession();
  }, []);

  const options = { clientSecret };
  return (
    <div className="stripe_setup_session">
      <div className="stripe_setup_session_form">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </div>
  );
};

export default StripeSetupSession;
