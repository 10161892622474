import React, {useEffect, useState, useContext} from 'react';
import './Course.scss';
import  { getCourseChapter } from '../services/api.services';
import toast from 'react-hot-toast';
import { AuthContext } from '../App';

const Course = ({courseData, currentCourseName}) => {
  const {state, dispatch } = useContext(AuthContext);
  const [courseIntroduction, setCourseIntroduction] = useState(null)

  useEffect(() => {
   getCourseIntroduction(courseData.chapter[0].id); 
  },[])

  let header = {
    'Authorization': "Token "+ state.token
  }

  const getCourseIntroduction = async(id) => {
    await getCourseChapter(header, id)
    .then(resp => {
      if(resp.data.isSuccess) {
        setCourseIntroduction(resp.data.dataInfo);
      }
      else{
        toast.error('Something went worng!');
      }
    })
    .catch(err =>{
        toast.error('Something went wrong!');
    });
  }
  return (
    <div className='app-parent-container'>
      <div className='app-course'>
        <div className='app-course_body'>
          <div className='app-course_body-title'>Let's Learn <b>{currentCourseName}</b></div>
          {courseIntroduction !== null && 
            <div className='app-course_body-content'>
              {courseIntroduction.documents.map((data) => {
                return <div className='app-course_body-content-text'>{data.content}</div>
              })}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Course
