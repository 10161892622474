import React, {useState, useEffect, useContext} from 'react';
import { AuthContext } from '../../App';
import './AdminTraineeDetails.scss';
import '../../theme/_buttons.scss';
import { useLocation } from "react-router-dom";
import whiteDownload from '../../images/icons/admin/whiteDownload.png';
import blueDownload from '../../images/icons/admin/blueDownload.png';
import edit from '../../images/icons/admin/edit.png';
import AdminCustomCheckbox from '../components/AdminCustomCheckbox';
import CourseProgress from '../../components/CourseProgress';
import {getTraineeDetailsApi, getTraineeCoursesDetailsPaginateApi, updateTraineeDetailsApi, updateTraineeAllCertificateApi, updateTraineeCertificateApi} from '../services/api.services';
import toast from "react-hot-toast";
import AppPagination from '../components/AppPagination';
import { useNavigate } from "react-router-dom";

const AdminTraineeDetails = () => {
    const { state, dispatch } = useContext(AuthContext);
    const location = useLocation();
    const trainee = location.state;
    const [isEdit, setIsEdit] = useState(false);
    const [traineeDetails, setTraineeDetails] = useState([])
    const [traineeCourseData, setTraineeCourseData] = useState([])
    const navigate = useNavigate();

    // Add these useState for the CustomCheckbox, response data
    const [checkedData, setCheckedData] = useState([])

    const [validationError, setValidationError] = useState("");
    const [editedTraineeDetails, setEditedTraineeDetails] = useState({
        first_name: "",
        last_name: "",
        mobile_number: "",
        email: "",
      });

    // These lines of code are for the pagination
    var recordsPerPage = 5
    const [pageNo, setPageNo] = useState(1)
    const [dataCount, setDataCount] = useState(0)

    useEffect(()=>{
        getTraineeDetails()
    },[])
    useEffect(()=>{
        getTraineeCoursesDetails()
    },[pageNo])

    let header = {
        Authorization: "Token " + state.token,
    };

    const getTraineeDetails = async() => {
        await getTraineeDetailsApi(header, trainee)
        .then((response) => {
            if(response.status == 200){
                setTraineeDetails(response.data)
            }
            else{
                console.log(response)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const getTraineeCoursesDetails = async() => {
        await getTraineeCoursesDetailsPaginateApi(header, pageNo, recordsPerPage, trainee)
        .then((response) => {
            if(response.status == 200){
                // handleTraineeCourseData(response.data.results)
                setTraineeCourseData(response.data.results)
                setDataCount(response.data.count)
            }
            else{
                console.log(response)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const handleEnrollCourses = (traineeId) => {
        const trainee = [traineeId]
        navigate("/admin/enroll-courses", { state:  trainee});
    }

    const toggleEditMode = () => {
        setIsEdit(!isEdit);
        document.getElementsByClassName('trainee-detail-input').value = ''
    }

    const handleSubmit = async () => {
        try {
            const isEdited = Object.keys(editedTraineeDetails).some(
                (field) => editedTraineeDetails[field] !== ""
            );
        
            if (!isEdited) {
                toast.error("No changes made.");
                return;
            }
        
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const nameRegex = /^[A-Za-z]+$/;
            const phoneRegex = /^\d+$/;
            if (editedTraineeDetails.first_name) {
                if (!nameRegex.test(editedTraineeDetails.first_name)) {
                setValidationError(
                    "First name should contain only alphabetic characters"
                );
                return;
                }
            }
            if (editedTraineeDetails.last_name) {
                if (!nameRegex.test(editedTraineeDetails.last_name)) {
                setValidationError(
                    "Last name should contain only alphabetic characters"
                );
                return;
                }
            }
            if (editedTraineeDetails.mobile_number) {
                if (!phoneRegex.test(editedTraineeDetails.mobile_number)) {
                setValidationError("Mobile number should contain only numerics");
                return;
                } else if (
                editedTraineeDetails.mobile_number.length < 10 ||
                editedTraineeDetails.mobile_number.length > 15
                ) {
                setValidationError("Mobile number should be between 10 to 15 digits");
                return;
                }
            }
            if (editedTraineeDetails.email) {
                if (!emailRegex.test(editedTraineeDetails.email)) {
                setValidationError("Invalid email format");
                return;
                }
            }
        
            const updatedFields = {};
        
            for (const field in editedTraineeDetails) {
                if (editedTraineeDetails[field]) {
                updatedFields[field] = editedTraineeDetails[field];
                }
            }
        
            const res = await updateTraineeDetailsApi(header, trainee, updatedFields);
        
            if (res.status === 200) {
                toggleEditMode();
                setTraineeDetails((prevDetails) => ({
                ...prevDetails,
                ...updatedFields,
                }));
                setEditedTraineeDetails({
                first_name: "",
                last_name: "",
                mobile_number: "",
                email: "",
                });
                toast.success("User details updated successfully");
            } else {
                setValidationError("");
                toast.error(res.data.message);
            }
        } catch (error) {
          toast.error("An error occurred while updating user details.");
        }
    };
    const handleInputChange = (field, value) => {
        setEditedTraineeDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value,
        }));
    };

    const cancelEdit = () => {
        setEditedTraineeDetails({
          first_name: "",
          last_name: "",
          mobile_number: "",
          email: "",
        });
        setValidationError("");
        toggleEditMode();
    };

    const handleAllCertificateDownload = async(trainee) => {
        await updateTraineeAllCertificateApi(header, trainee)
        .then((response) => {
            if(response.status === 200){
                const certificatesBlob = new Blob([response.data]);
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(certificatesBlob);
                link.download = 'allcertificates.zip';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else{
                console.log(response)
                // toast.error(response.message);
            }
        })
        .catch((error) => {
            toast.error("Failed to download certificate");
        })
    }

    const handleCertificateDownload = async(status, userCourse) => {
        if(status === 'completed'){
            await updateTraineeCertificateApi(header, userCourse)
            .then((response) => {
                if(response.status === 200){
                    const contentType = response.headers["content-type"];
                    let extension = "png";
                    if (contentType.includes("jpeg")) {
                        extension = "jpeg";
                    } else if (contentType.includes("gif")) {
                        extension = "gif";
                    }
                    const certificateBlob = new Blob([response.data], { type: contentType });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(certificateBlob);
                    link.download = `certificate.${extension}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                else{
                    toast.error("Failed to download certificate");
                }
            })
            .catch((error) => {
                toast.error(error);
            })
        }
        else{
            toast.error("This Course is not completed by the user.");
        }
    }
    
  return ( 
    <div className='admin-trainee_details'>
        <div className='admin-trainee_details-head'>
            <div className='admin-trainee_details-head-title'>{traineeDetails.full_name}</div>
            <div className='admin-trainee_details-head-back'>
                <button className='back-button' onClick={() => {
                    navigate("/admin/trainees");
                }}>Back</button>
            </div>
        </div>
        <div className='admin-trainee_details-body'>
            <div className='admin-trainee_details-body-content'>
                <div className='admin-trainee_details-body-content-titlebar'>
                    <div className='admin-trainee_details-body-content-titlebar-title'>Trainee Details</div>
                    <div className='admin-trainee_details-body-content-titlebar-user'>
                        <div className='admin-trainee_details-body-content-titlebar-user-icon'>
                            {traineeDetails.short_name}
                        </div>
                        <div className='admin-trainee_details-body-content-titlebar-user-text'>
                            <div className='admin-trainee_details-body-content-titlebar-user-text-name'>
                                {traineeDetails.full_name}
                            </div>
                            <div className='admin-trainee_details-body-content-titlebar-user-text-email'>
                                {traineeDetails.email}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='admin-trainee_details-body-content-courses'>
                    <div className='admin-trainee_details-body-content-courses-header'>
                        <div className='admin-trainee_details-body-content-courses-header-enrolled'>
                            <div className='admin-trainee_details-body-content-courses-header-enrolled-title'>Enrolled Courses</div>
                            <div className='admin-trainee_details-body-content-courses-header-enrolled-search'></div>
                            {/* <div className='admin-trainee_details-body-content-courses-header-enrolled-filter'></div> */}
                            <div className='admin-trainee_details-body-content-courses-header-enrolled-button'>
                                <button className='admin-trainee_details-body-content-courses-header-enrolled-button-enroll primary-button' onClick={()=>handleEnrollCourses(traineeDetails.id)}>Enroll Courses</button>
                            </div>
                        </div>
                        <div className='admin-trainee_details-body-content-courses-header-subhead'>
                            <div className='admin-trainee_details-body-content-courses-header-subhead-courses'>No of Courses: {traineeDetails.courses_enrolled}</div>
                            <div className='admin-trainee_details-body-content-courses-header-subhead-allcertificate'>
                                <button className='secondary-button'  onClick={() => handleAllCertificateDownload(traineeDetails.id)}>
                                    <img src={blueDownload} alt='download all'></img>
                                    All Certificate
                                </button>
                            </div>
                            
                            
                        </div>
                    </div>
                    <div className='admin-trainee_details-body-content-courses-table'>
                        <div className='admin-trainee_details-body-content-courses-table-head'>
                            <div className='admin-trainee_details-body-content-courses-table-head-item'>
                                <AdminCustomCheckbox selectAll={true} setCheckedData={setCheckedData} checkedData={checkedData} responseData={traineeCourseData[0]}></AdminCustomCheckbox>
                                Courses
                            </div>
                            <div className='admin-trainee_details-body-content-courses-table-head-item'>
                                Status
                            </div>
                            <div className='admin-trainee_details-body-content-courses-table-head-item'>
                                Duration
                            </div>
                            <div className='admin-trainee_details-body-content-courses-table-head-item'>
                                Delete
                            </div>
                        </div>
                        <div className='admin-trainee_details-body-content-courses-table-body'>
                            {traineeCourseData.length > 0 &&
                                traineeCourseData.map((data, index) => (
                                    <div className='admin-trainee_details-body-content-courses-table-body-row'>
                                        <div className='admin-trainee_details-body-content-courses-table-body-row-item'>
                                            <AdminCustomCheckbox selectAll={false} data_id={data.course.id} setCheckedData={setCheckedData} checkedData={checkedData}></AdminCustomCheckbox>
                                            {data.course.name}
                                        </div>
                                        <div className='admin-trainee_details-body-content-courses-table-body-row-item'>
                                            <div className='admin-trainee_details-body-content-courses-table-body-row-item-progress'>
                                                <div className='admin-trainee_details-body-content-courses-table-body-row-item-progress-label' style={{color: data.status === 'completed' ? '#067D27' : data.status == 'in_progress' ? '#16B2BB' : '#FDB01B'}}>{data.status === 'completed' ? 'Completed' : data.status == 'in_progress' ? 'In Progress' : 'Pending'}</div>
                                                <CourseProgress completed={data.status === 'completed' ? '100' : data.status == 'in_progress' ? '50' : '0'} bgcolor={data.status === 'completed' ? '#067D27' : data.status == 'in_progress' ? '#CCF4F7' : '#FFF0D3'} indicator={data.status === 'completed' ? '#067D27' : data.status == 'in_progress' ? '#16B2BB' : '#FFF0D3'} />
                                            </div>
                                        </div>
                                        <div className='admin-trainee_details-body-content-courses-table-body-row-item'>
                                            {data.course.duration}
                                        </div>
                                        <div className='admin-trainee_details-body-content-courses-table-body-row-item'>
                                            <button className={data.status === 'completed' ? 'primary-button' : 'disabled-button'} onClick={() => handleCertificateDownload(data.status, data.id)}>
                                                <img src={whiteDownload} alt='download'></img>
                                                Certificate
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div> 
                    {traineeCourseData.length > 0 && (
                        <AppPagination setCurrentPage={setPageNo} currentPage={pageNo} recordsPerPage={recordsPerPage} data_count={dataCount} />
                    )}  
                </div>
                <div className='admin-trainee_details-body-content-details'>
                    <div className='admin-trainee_details-body-content-details-title'>
                        Personal Info
                        <span className='admin-trainee_details-body-content-details-title-edit'  onClick={toggleEditMode}>
                            <img src={edit} alt='edit'></img>
                        </span>
                    </div>
                    <div className='admin-trainee_details-body-content-details-tab'>
                        <div className='admin-trainee_details-body-content-details-tab-user'>
                            <div className='admin-trainee_details-body-content-details-tab-user-icon'>
                                {traineeDetails.short_name}
                            </div>
                            <div className='admin-trainee_details-body-content-details-tab-user-text'>
                                <div className='admin-trainee_details-body-content-details-tab-user-text-name'>
                                    {traineeDetails.full_name}
                                </div>
                                <div className='admin-trainee_details-body-content-details-tab-user-text-email'>
                                    {traineeDetails.email}
                                </div>
                            </div>
                        </div>
                        {isEdit ? (
                        <div className='admin-trainee_details-body-content-details-tab-form'>
                            <div className='admin-trainee_details-body-content-details-tab-form-name'>
                                <div className='admin-trainee_details-body-content-details-tab-form-name-details edit-mode'>
                                    <div className='admin-trainee_details-body-content-details-tab-form-name-details-label'>
                                        First Name:
                                    </div>
                                    <div className='admin-trainee_details-body-content-details-tab-form-name-details-data'>
                                        <input type='text' placeholder={traineeDetails.first_name} onChange={(e) => handleInputChange("first_name", e.target.value)}></input>
                                    </div>
                                </div>
                                <div className='admin-trainee_details-body-content-details-tab-form-name-details edit-mode'>
                                    <div className='admin-trainee_details-body-content-details-tab-form-name-details-label'>
                                        Last Name:
                                    </div>
                                    <div className='admin-trainee_details-body-content-details-tab-form-name-details-data'>
                                        <input type='text' placeholder={traineeDetails.last_name} onChange={(e) => handleInputChange("last_name", e.target.value)}></input>
                                    </div>
                                </div>
                            </div>
                            <div className='admin-trainee_details-body-content-details-tab-form-contact'>
                                <div className='admin-trainee_details-body-content-details-tab-form-contact-details edit-mode'>
                                    <div className='admin-trainee_details-body-content-details-tab-form-contact-details-label'>
                                        Phone:
                                    </div>
                                    <div className='admin-trainee_details-body-content-details-tab-form-contact-details-data'>
                                        <input type='text' placeholder={traineeDetails.mobile_number} onChange={(e) => handleInputChange("mobile_number", e.target.value)}></input>
                                    </div>
                                </div>
                                <div className='admin-trainee_details-body-content-details-tab-form-contact-details edit-mode'>
                                    <div className='admin-trainee_details-body-content-details-tab-form-contact-details-label'>
                                        Email:
                                    </div>
                                    <div className='admin-trainee_details-body-content-details-tab-form-contact-details-data'>
                                        <input type='email' placeholder={traineeDetails.email} onChange={(e) => handleInputChange("email", e.target.value)}></input>
                                    </div>
                                </div>
                            </div>
                            {validationError && (
                                <div className="admin-trainee_details-body-content-details-tab-form-error">
                                    {validationError}
                                </div>
                            )}
                            <div className="admin-trainee_details-body-content-details-tab-form-action">
                                <button
                                    className="primary-button"
                                    onClick={handleSubmit}
                                >
                                    Update
                                </button>
                                <button
                                    className="finish-button"
                                    onClick={cancelEdit}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        ) : (
                        <div className='admin-trainee_details-body-content-details-tab-form'>
                            <div className='admin-trainee_details-body-content-details-tab-form-name'>
                                <div className='admin-trainee_details-body-content-details-tab-form-name-details'>
                                    <div className='admin-trainee_details-body-content-details-tab-form-name-details-label'>
                                        First Name:
                                    </div>
                                    <div className='admin-trainee_details-body-content-details-tab-form-name-details-data'>
                                        <input className='read-only-mode' type='text' value={traineeDetails.first_name}></input>
                                    </div>
                                </div>
                                <div className='admin-trainee_details-body-content-details-tab-form-name-details'>
                                    <div className='admin-trainee_details-body-content-details-tab-form-name-details-label'>
                                        Last Name:
                                    </div>
                                    <div className='admin-trainee_details-body-content-details-tab-form-name-details-data'>
                                        <input className='read-only-mode' type='text' value={traineeDetails.last_name}></input>
                                    </div>
                                </div>
                            </div>
                            <div className='admin-trainee_details-body-content-details-tab-form-contact'>
                                <div className='admin-trainee_details-body-content-details-tab-form-contact-details'>
                                    <div className='admin-trainee_details-body-content-details-tab-form-contact-details-label'>
                                        Phone:
                                    </div>
                                    <div className='admin-trainee_details-body-content-details-tab-form-contact-details-data'>
                                        <input className='read-only-mode' type='text' value={traineeDetails.mobile_number}></input>
                                    </div>
                                </div>
                                <div className='admin-trainee_details-body-content-details-tab-form-contact-details'>
                                    <div className='admin-trainee_details-body-content-details-tab-form-contact-details-label'>
                                        Email:
                                    </div>
                                    <div className='admin-trainee_details-body-content-details-tab-form-contact-details-data'>
                                        <input className='read-only-mode' type='text' value={traineeDetails.email}></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminTraineeDetails
