import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { FaCaretDown, FaCaretUp, FaTimes } from "react-icons/fa";
import { createAdminChapter } from "../services/api.services";
import "./CreateClassPopup.scss";

const CreateClassPopup = ({
  header,
  allCourseData,
  allModuleData,
  onSuccess,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModuleDropdownOpen, setIsModuleDropdownOpen] = useState(true);
  const [isCourseDropdownOpen, setIsCourseDropdownOpen] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState("Select Course");
  const [selectedModule, setSelectedModule] = useState("Select Module");
  const [filteredModuleData, setFilteredModuleData] = useState([])
  const [classData, setClassData] = useState({
    name: "",
    module: "",
    course: "",
    position: "",
    icon: null,
  });

  const toggleModal = () => {
    setIsCourseDropdownOpen(!isCourseDropdownOpen);
    setIsModuleDropdownOpen(!isModuleDropdownOpen);
    setSelectedCourse("Select Course");
    setSelectedModule("Select Module");
    setIsOpen(!isOpen);
    setClassData({
      name: "",
      module: "",
      course: "",
      position: "",
      icon: null,
    });
  };

  const toggleModuleDropdown = () => {
    if (!selectedCourse || selectedCourse === "Select Course") {
      toast.error("Please select a course first");
    } else {
      setIsModuleDropdownOpen(!isModuleDropdownOpen);
    }
  };

  const toggleCourseDropdown = () => {
    if (!isModuleDropdownOpen) {
      setIsCourseDropdownOpen(!isCourseDropdownOpen);
    }
  };

  useEffect(() => {
    if (classData.course && classData.course !== "Select Course") {
      const filteredModules = allModuleData.filter(
        (module) => module.course_id === classData.course
      );
      setFilteredModuleData(filteredModules);
    } else {
      setFilteredModuleData([]);
    }
  }, [classData.course, allModuleData]);

  const selectCourse = (course) => {
    setSelectedCourse(course);
    setIsCourseDropdownOpen(false);
    const selectedCourseData = allCourseData.find(
      (item) => item.name === course
    );
    if (selectedCourseData) {
      setClassData((prevData) => ({
        ...prevData,
        course: selectedCourseData.id,
        module: "", 
      }));
    }
  };

  const selectModule = (module) => {
    setSelectedModule(module);
    setIsModuleDropdownOpen(false);
    const selectedModuleData = allModuleData.find(
      (item) => item.name === module
    );
    if (selectedModuleData) {
      setClassData((prevData) => ({
        ...prevData,
        module: selectedModuleData.id,
      }));
    }
  };

  function uploadFile(event) {
    const fileName = event.target.files[0].name;
    document.getElementById("file-name").textContent = fileName;
  }
  const handleSubmit = async () => {
    // Validation
    if (
      classData.name.trim() === "" ||
      classData.position === "" ||
      classData.course === "" ||
      classData.module === ""
    ) {
      toast.error("All fields are required");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", classData.name);
      formData.append("position", classData.position);
      formData.append("course", classData.course);
      formData.append("module", classData.module);
      if (classData.icon) {
        formData.append("icon", classData.icon);
      }
      const response = await createAdminChapter(header, formData);

      if (response.status === 200 && response.data.isSuccess) {
        toast.success("Class created successfully");
        toggleModal();
        setClassData({
          name: "",
          module: "",
          course: "",
          position: "",
          icon: null,
        });
        onSuccess();
      } else {
        toast.error("Failed to create class");
      }
    } catch (error) {
      toast.error("Error creating class");
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setClassData({ ...classData, [id]: value });
  };

  return (
    <div>
      <button className="create_class-modal-button" onClick={toggleModal}>
        <span className="create_class-modal-button-icon">+</span>
        <span className="create_class-modal-button-text">Add Class</span>
      </button>

      {isOpen && (
        <div className="create_class-modal-overlay">
          <div className="create_class-modal-content">
            <div
              onClick={toggleModal}
              className="create_class-modal-content-close_button"
            >
              <FaTimes />
            </div>
            <div className="create_class-modal-content-title">Create Class</div>

            <label htmlFor="courseName">Class Name</label>
            <input
              type="text"
              id="name"
              placeholder="eg: Class 1"
              value={classData.name}
              onChange={handleInputChange}
            />
            {/* <label htmlFor="courses">Module</label> */}
            <div className="dropdown-container">
              <div className="input-dropdown" onClick={toggleModuleDropdown}>
                <span>{selectedModule}</span>
                <span>
                  {isModuleDropdownOpen ? <FaCaretUp /> : <FaCaretDown />}
                </span>
              </div>
              {isModuleDropdownOpen && (
                <div className="dropdown-list">
                  {allModuleData.map((module) => (
                    <div
                      className="dropdown-item"
                      key={module.id}
                      onClick={() => selectModule(module.name)}
                      // onClick={() => handleSelection(module.name, module.id, module.course_name)}
                    >
                      {module.name} ({module.course_name})
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* <label htmlFor="courses">Course</label> */}
            <div className="dropdown-container">
              <div className="input-dropdown" onClick={toggleCourseDropdown}>
                <span>{selectedCourse}</span>
                <span>
                  {isCourseDropdownOpen ? <FaCaretUp /> : <FaCaretDown />}
                </span>
              </div>
              {isCourseDropdownOpen && (
                <div className="dropdown-list">
                  {allCourseData.map((course) => (
                    <div
                      className="dropdown-item"
                      key={course.id}
                      onClick={() => selectCourse(course.name)}
                    >
                      {course.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* <label htmlFor="courses">Module</label> */}
            <div className="dropdown-container">
              <div className="input-dropdown" onClick={toggleModuleDropdown}>
                <span>{selectedModule}</span>
                <span>
                  {isModuleDropdownOpen ? <FaCaretUp /> : <FaCaretDown />}
                </span>
              </div>
              {isModuleDropdownOpen && (
                <div className="dropdown-list">
                  {filteredModuleData.map((module) => (
                    <div
                      className="dropdown-item"
                      key={module.id}
                      onClick={() => selectModule(module.name)}
                      // onClick={() => handleSelection(module.name, module.id, module.course_name)}
                    >
                      {module.name} ({module.course_name})
                    </div>
                  ))}
                </div>
              )}
            </div>
            <label htmlFor="position">Position</label>
            <input
              type="number"
              id="position"
              placeholder="Position"
              value={classData.position}
              onChange={handleInputChange}
            />

            <label htmlFor="courseThumbnail">Class Icon</label>
            <div className="inputfile-box">
              <input
                type="file"
                id="courseThumbnail"
                className="inputfile"
                onChange={uploadFile}
                placeholder="Choose File"
              />
              <label htmlFor="courseThumbnail">
                <span id="file-name" className="file-box"></span>
                <span className="file-button">Browse</span>
              </label>
            </div>

            <button
              className="create_class-modal-content-submit"
              onClick={handleSubmit}
            >
              Create Class
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateClassPopup;
