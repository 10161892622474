import React, { useState, useContext } from "react";
import dashIcon from "../../images/icons/dashboard.png";
import courseIcon from "../../images/icons/course.png";
import logoutIcon from "../../images/icons/logout.png";
import logo from "../../images/icons/logo.png";
import { useNavigate } from "react-router-dom";
import "./AdminSidebar.scss";
import {
  FaCaretDown,
  FaCaretUp,
  FaUserFriends,
  FaDollarSign,
  FaSignOutAlt,
  FaBars,
  FaBookOpen,
  FaBook,
  FaBoxes,
  FaBuffer,
} from "react-icons/fa";
import adminDashIcon from '../../images/icons/dashboard.png';
import adminCoursesIcon from '../../images/icons/admin/sidebar/courses.png';
import adminTraineesIcon from '../../images/icons/admin/sidebar/trainees.png';
import adminCreditIcon from '../../images/icons/admin/sidebar/credit.png';
import adminCourseSelectorIcon from '../../images/icons/go_back.png';
import adminLogoutIcon from '../../images/icons/logout.png';
import { AuthContext } from '../../App.js';
import appLogo from "../../images/icons/logo.png";
import appTabLogo from "../../images/icons/mobile_logo.png";

const AdminSidebar = ({ toggleSidebar, showSidebar }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleTabClick = (index) => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 500) {
      toggleSidebar();
    }
    document.getElementsByClassName('admin-sidebar-content-tab selected')[0].classList.remove('selected')
    if(!index.currentTarget.classList.contains('selected')){
      index.currentTarget.classList.add('selected')
    }
  };
  const handleLogout = () => {
    dispatch({
        type: 'LOGOUT',
        payload: {
          user: null,
          token: null
        }
    })
    navigate('/');
  };  

  return (
    <div className={showSidebar ? "admin-sidebar show-sidebar" : "admin-sidebar" }>
      <div className="admin-sidebar-content">
      <div className="admin-sidebar-content-logo">
        <div className="admin-sidebar-content-logo-img">
          <img src={appLogo} alt="logo" />
        </div>
        <div className="admin-sidebar-content-logo-tab">
          <img src={appTabLogo} alt="logo" />
        </div>
      </div>
        <div className="admin-sidebar-content-tab selected" key={1}
          onClick={(key) => {
            handleTabClick(key);
            navigate("/admin/");
          }}
        >
          <div className="admin-sidebar-content-tab-icon">
            <img src={adminDashIcon} alt="dashboard"></img>
          </div>
          <div className="admin-sidebar-content-tab-text">Dashboard</div>
        </div>
        <div className="admin-sidebar-content-tab" key={2}
          onClick={(key) => {
            handleTabClick(key);
            navigate("/admin/courses");
          }}>
          <div className="admin-sidebar-content-tab-icon">
            <img src={adminCoursesIcon} alt="courses"></img>
          </div>
          <div className="admin-sidebar-content-tab-text">
            All Courses 
          </div>
        </div>
        <div
          className="admin-sidebar-content-tab" key={3}
          onClick={(key) => {
            handleTabClick(key);
            navigate("/admin/trainees");
          }}
        >
          <div className="admin-sidebar-content-tab-icon">
            <img src={adminTraineesIcon} alt="trainees"></img>
          </div>
          <div className="admin-sidebar-content-tab-text">Trainees</div>
        </div>
        <div
          className="admin-sidebar-content-tab" key={4}
          onClick={(key) => {
            handleTabClick(key);
            navigate("/admin/credits");
          }}
        >
          <div className="admin-sidebar-content-tab-icon">
            <img src={adminCreditIcon} alt="credit"></img>
          </div>
          <div className="admin-sidebar-content-tab-text">
            Purchase
            <br /> Credits
          </div>
        </div>
        <div
          className="admin-sidebar-content-tab" key={5}
          onClick={(key) => {
            handleTabClick(key);
            navigate("/");
          }}
        >
          <div className="admin-sidebar-content-tab-icon">
            <img src={adminCourseSelectorIcon} alt="course selector"></img>
          </div>
          <div className="admin-sidebar-content-tab-text">
            Course <br />
            Selector
          </div>
        </div>
        <div
          className="admin-sidebar-content-tab" key={6}
          onClick={(key) => {
            handleTabClick(key);
            handleLogout();
          }}
        >
          <div className="admin-sidebar-content-tab-icon">
            <img src={adminLogoutIcon} alt="logout"></img>
          </div>
          <div className="admin-sidebar-content-tab-text">
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
