import React, { useState } from "react";
import "./FlippingCard.scss";
import { FaRedoAlt, FaUndoAlt } from "react-icons/fa";

const FlippingCard = ({content}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="container">
      <div className="card" onClick={handleFlip}>
        <div className={`cardBack ${isFlipped ? "flipped" : ""}`}>
          <div className="card-content">
            {content.content}
          </div>
          <div className="card-icon"> <FaUndoAlt style={{ color: '#05177d' }} /> </div>
        </div>
        <div className={`cardFront ${isFlipped ? "flipped" : ""}`}>
        <div className="card-content">
            {content.title} 
          </div>
          <div className="card-icon"> <FaRedoAlt style={{ color: 'white' }}/> </div>
        </div>
        
      </div>
    </div>
  );
};

export default FlippingCard;
