import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminEnrollCourses.scss";
import { AuthContext } from "../../App";
import { getAdminCourseData, enrollCourse } from "../services/api.services";
import GridLayout from "../components/GridLayout";
import toast from "react-hot-toast";
import adminCourse from "../../images/icons/admin-class-list.jpg";
import { useLocation } from "react-router-dom";
import PopupModal from "../components/PopupModal";
import { useCredit } from "../components/CreditContext";

const AdminEnrollCourses = () => {
  const location = useLocation();
  const { state, dispatch } = useContext(AuthContext);
  const [courseData, setCourseData] = useState([]);
  const [enrollCourseIds, setEnrollCourseIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreditNeeded, setIsCreditNeeded] = useState(false);
  const navigate = useNavigate();
  const { fetchCreditDetails, isSubscription } = useCredit();

  const trainees = location.state;

  useEffect(() => {
    getCourseData();
  }, []);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getCourseData = async () => {
    try {
      const response = await getAdminCourseData(header);
      if (response.data.isSuccess) {
        setCourseData(response.data.dataInfo);
      } else {
        toast.error("Failed to fetch course data");
      }
    } catch (error) {
      toast.error("Error fetching course data: " + error.message);
    }
  };

  const handleCourseEnrollToggle = (courseId) => {
    if (!enrollCourseIds.includes(courseId)) {
      setEnrollCourseIds([...enrollCourseIds, courseId]);
    } else {
      setEnrollCourseIds(enrollCourseIds.filter((item) => item !== courseId));
    }
  };
  const handleTopUpClick = async () => {
    const enrollData = {
      courses: enrollCourseIds.map((id) => id),
      trainees: trainees.map((id) => id),
    };
    // console.log(enrollData)
    try {
      const response = await enrollCourse(header, enrollData);
      console.log(response.data);
      if (response.data.isSuccess) {
        setIsOpen(false);
        navigate("/admin/trainees");
        toast.success("Enrollment successful");
        if (!isSubscription) {
          fetchCreditDetails();
        }
      } else {
        if (response.data.errorType === "credits_needed") {
          toast.error(response.data.message);
          setIsCreditNeeded(true);
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      toast.error("Error enrolling courses: " + error.message);
    }
  };

  const handleEnrollButton = () => {
    setIsOpen(true);
  };
  const handlePopupCancel = () => {
    setIsOpen(false);
  };
  return (
    <div className="admin-enroll-courses-body">
      <div className="admin-enroll-courses-body_head">
        <h2>Enroll Courses</h2>
        <button
          className="admin-enroll-courses-body_head-button"
          onClick={() => {
            navigate("/admin/trainees");
          }}
        >
          Back
        </button>
      </div>
      <div className="admin-enroll-courses-body_container">
        <div className="admin-enroll-courses-body_container-head">
          <p>Select one or more courses for user</p>
          <button
            className={
              "admin-enroll-courses-body_container-head-button " +
              (enrollCourseIds.length !== 0 ? "active" : "")
            }
            disabled={enrollCourseIds.length === 0}
            onClick={handleEnrollButton}
          >
            Enroll User
          </button>
        </div>
        <div className="admin-enroll-courses-body_container-body">
          <div className="admin-enroll-courses-body_container-body-filter">
            <ul className="admin-enroll-courses-body_container-body-filter-list">
              <li className="admin-enroll-courses-body_container-body-filter-list-item">
                All Courses
              </li>
            </ul>
          </div>
          <div className="admin-enroll-courses-body_container-body-content">
            <div className="admin-enroll-courses-body_container-body-content-item">
              <GridLayout rows={5}>
                {courseData.map((course, index) => {
                  return (
                    <div
                      className={
                        "admin-enroll-courses-body_container-body-content-item-details-courses " +
                        (enrollCourseIds.includes(course.id) ? "selected" : "")
                      }
                      key={course.id}
                      onClick={() => handleCourseEnrollToggle(course.id, index)}
                    >
                      <div className="admin-enroll-courses-body_container-body-content-item-details-courses-img">
                        <img src={course.icon} alt="admin-course"></img>
                      </div>
                      <div className="admin-enroll-courses-body_container-body-content-item-details-courses-text">
                        <h3>{course.name}</h3>
                        <p className="admin-enroll-courses-body_container-body-content-item-details-courses-text-module">
                          {course.module_count} Modules
                        </p>
                        <p>1 individual course credit per trainee</p>
                      </div>
                    </div>
                  );
                })}
              </GridLayout>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <PopupModal setIsOpen={setIsOpen}>
          <div className="admin-enroll-courses_modal">
            <h2 className="admin-enroll-courses_modal-title">Enroll Courses</h2>
            <div className="admin-enroll-courses_modal-cards">
              <div className="admin-enroll-courses_modal-cards-trainees">
                <h5>No. of Trainees</h5>
                <h1>{trainees.length}</h1>
              </div>
              <div className="admin-enroll-courses_modal-cards-courses">
                <h5>No. of Courses</h5>
                <h1>{enrollCourseIds.length}</h1>
              </div>
            </div>
            <div className="admin-enroll-courses_modal-info"></div>
            <div className="admin-enroll-courses_modal-buttons">
              <button
                className="admin-enroll-courses_modal-buttons-button"
                onClick={handlePopupCancel}
              >
                Cancel
              </button>
              {isCreditNeeded ? (
                <button
                  id="topup-button"
                  className="admin-enroll-courses_modal-buttons-button"
                  onClick={() => navigate("/admin/credits")}
                >
                  TopUp
                </button>
              ) : (
                <button
                  id="topup-button"
                  className="admin-enroll-courses_modal-buttons-button"
                  onClick={handleTopUpClick}
                >
                  Proceed
                </button>
              )}
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default AdminEnrollCourses;
