import React, { useState } from 'react';
import './ChapterAccordion.scss';
import { FaPlus, FaMinus } from "react-icons/fa";

const ChapterAccordion = ({ sections }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion-container">
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          <div
            className={`accordion ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleAccordion(index)}
          >
            
            <div className='button-text'>{section.title}</div>
            <div className='button-icon'>{activeIndex === index ? <FaMinus /> : <FaPlus />}</div>
          </div>
          <div className={`panel ${activeIndex === index ? 'open' : ''}`}>
            <p>{section.content}</p>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ChapterAccordion;
