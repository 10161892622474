import React from "react";
import "./MockTestResult.scss";
import ViewAnswerPopup from "./ViewAnswerPopup";
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import toast from "react-hot-toast";

const MockTestResult = ({ dataInfo, onBack }) => {
  const attemptResult = dataInfo.attempt_result;

  return (
    <div className="mock_test_result">
      <div className="mock_test_result-title">
        <div className="mock_test_result-title-text">Quiz Completed</div>
        <div className="mock_test_result-title-details">
          <div className="mock_test_result-title-details-left">
            <span style={{ fontWeight: "bold" }}>Marks Obtained</span>:{" "}
            <span>{dataInfo.marks} points</span>
          </div>
          <div className="mock_test_result-title-details-right">
            <span style={{ fontWeight: "bold" }}>Result</span>:{" "}
            <span>{dataInfo.is_passed ? "Passed" : "Failed"}</span>
          </div>
        </div>
      </div>
      <div className="mock_test_result-body">
        <div className="mock_test_result-body-table">
          <div className="mock_test_result-body-table-head">
            <div className="mock_test_result-body-table-head-item">
              Question No.
            </div>
            <div className="mock_test_result-body-table-head-item">Module</div>
            <div className="mock_test_result-body-table-head-item">
              Answer Status
            </div>
            <div className="mock_test_result-body-table-head-item">
              Correct Answer
            </div>
          </div>
          <div className="mock_test_result-body-table-body">
            {attemptResult.map((data, index) => (
              <div
                className="mock_test_result-body-table-body-row"
                key={data.id}
              >
                <div className="mock_test_result-body-table-body-row-item">
                  {index + 1}
                </div>
                <div className="mock_test_result-body-table-body-row-item">
                  {data.module_name}
                </div>
                <div className="mock_test_result-body-table-body-row-item">
                  {data.answer_status ? (
                    <FaRegCheckCircle style={{ color: "green" }} />
                  ) : (
                    <FaRegTimesCircle style={{ color: "red" }} />
                  )}
                </div>
                <div className="mock_test_result-body-table-body-row-item">
                  {!data.answer_status && (
                    <button>
                      <ViewAnswerPopup
                        question_number={index + 1}
                        module_name = {data.module_name}
                        question_text={data.text}
                        selected_answer={data.selected_answer}
                        correct_answer={data.correct_answer}
                        answers={data.answers}
                      />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="mock_test_result-body-table-bottom">
            <button onClick={onBack}>Back To Mock Tests</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MockTestResult;
