import React, { useState, useContext, useEffect } from "react";
import AdminSidebar from "../components/AdminSidebar";
import AdminHeader from "../components/AdminHeader";
import AdminRoutes from "../components/AdminRoutes";
import { AuthContext } from "../../App";
import "./AdminDashboard.scss";
import { CreditProvider, useCredit } from "../components/CreditContext";

const AdminDashboardContent = () => {
  const { state, dispatch } = useContext(AuthContext);
  const { creditBalance, fetchCreditDetails, isSubscription } = useCredit();
  const [showSidebar, setShowSidebar] = useState(true);
  
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setShowSidebar(false);
      } else {
        setShowSidebar(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="admin-dashboard">
      <div className="admin-dashboard-header">
        <AdminHeader toggleSidebar={toggleSidebar} creditBalance={creditBalance} isSubscription={isSubscription} />
      </div>
      <div className="admin-dashboard-body">
        <div className="admin-dashboard-body-sidebar">
          <AdminSidebar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
        </div>
        <div className="admin-dashboard-body-content">
          <AdminRoutes fetchCreditDetails={fetchCreditDetails} isSubscription={isSubscription} /> 
        </div>
      </div>
    </div>
  );
};

const AdminDashboard = () => (
  <CreditProvider>
    <AdminDashboardContent />
  </CreditProvider>
);

export default AdminDashboard;
