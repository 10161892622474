import React, { useState } from "react";
import "./ViewAnswerPopup.scss";

const ViewAnswerPopup = ({ question_number, module_name, question_text, selected_answer, correct_answer, answers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="view_answer_popup-button" onClick={togglePopup}>
        View Answer
      </div>

      {isOpen && (
        <div className="view_answer_popup-overlay view_answer_popup-open">
          <div className="view_answer_popup-body view_answer_popup-open">
            <div className="view_answer_popup-body-module_name">{module_name}</div>
            <div className="view_answer_popup-body-question">
              <span>Q.{question_number}</span> {question_text}
            </div>
            <div className="view_answer_popup-body-answers">
              {answers.map((answer) => (
              <div
                key={answer.id}
                className="view_answer_popup-body-answers-answer"
                style={{
                  backgroundColor:
                    answer.id === correct_answer
                      ? "#c0ffc0"
                      : answer.id === selected_answer
                      ? "#f7a8a8"
                      : "transparent",
                }}
              >
                {answer.text}
              </div>
            ))}
            </div>         
            <button className="back-button view_answer_popup-body-close" onClick={togglePopup}>
              Back to Results
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAnswerPopup;
