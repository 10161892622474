import React, { useState } from "react";
import { FaRegTrashAlt, FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";
import { deleteAdminChapter } from "../services/api.services";
import "./DeleteClassPopup.scss";

const DeleteClassPopup = ({ header, classID, name, moduleName, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteAdminChapter(header, classID);
      if (response.status === 200 && response.data.isSuccess) {
        toast.success(`Class "${name}" from Module "${moduleName}" deleted successfully`);
        toggleModal();
        onSuccess();
      } else {
        toast.error("Failed to delete class");
      }
    } catch (error) {
      toast.error("Error deleting class: " + error.message);
    }
  };

  return (
    <div>
      <FaRegTrashAlt className="icon-trash" onClick={toggleModal} />

      {isOpen && (
        <div className="delete_class_popup-overlay">
          <div className="delete_class_popup-content">
            <div onClick={toggleModal} className="delete_class_popup-content-close_button">
              <FaTimes />
            </div>
            <div className="delete_class_popup-content-title">Remove Class</div>
            <div className="delete_class_popup-content-text">
              Are you sure you want to delete class "{name}" from module "{moduleName}" permanently?
            </div>

            <div className="delete_class_popup-content-action">
              <button className="delete_class_popup-content-action-confirm" onClick={handleDelete}>
                Confirm
              </button>
              <button className="delete_class_popup-content-action-cancel" onClick={toggleModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteClassPopup;
