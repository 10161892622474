import React, { useState } from "react";
import { FaRegTrashAlt, FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";
import "./DeleteModulePopup.scss";
import { deleteAdminModule } from "../services/api.services";

const DeleteModulePopup = ({
  moduleId,
  moduleName,
  courseName,
  header,
  onSuccess,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleConfirm = async () => {
    try {
      const response = await deleteAdminModule(header, moduleId);
      if (response.status === 200 && response.data.isSuccess) {
        toast.success(`Module "${moduleName}" deleted successfully`);
        onSuccess();
        toggleModal();
      } else {
        toast.error(`Failed to delete module "${moduleName}"`);
      }
    } catch (error) {
      toast.error(`Error deleting module: ${error.message}`);
    }
  };

  return (
    <div>
      <FaRegTrashAlt className="icon-trash" onClick={toggleModal} />

      {isOpen && (
        <div className="delete_module_popup-overlay">
          <div className="delete_module_popup-content">
            <div
              onClick={toggleModal}
              className="delete_module_popup-content-close_button"
            >
              <FaTimes />
            </div>
            <div className="delete_module_popup-content-title">
              Remove Module
            </div>
            <div className="delete_module_popup-content-text">
              Are you sure you want to delete {moduleName} from {courseName}{" "}
              permanently?
            </div>

            <div className="delete_module_popup-content-action">
              <button
                className="delete_module_popup-content-action-confirm"
                onClick={handleConfirm}
              >
                Confirm
              </button>
              <button
                className="delete_module_popup-content-action-cancel"
                onClick={toggleModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteModulePopup;
