import React, { useState } from "react";
import { FaRegTrashAlt, FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";
import "./DeleteTraineePopup.scss";
import { deleteTraineeData } from "../services/api.services";

const DeleteTraineePopup = ({ idArray, header, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleConfirm = async () => {
    try {
      const response = await deleteTraineeData(header, idArray);
      if (response.status === 200 && response.data.isSuccess) {
        toast.success(`Users removed successfully!!`);
        onSuccess();
        toggleModal();
      } else {
        toast.error(`Failed to remove user`);
      }
    } catch (error) {
      toast.error(`Error deleting user: ${error.message}`);
    }
  };

  return (
    <div>
      <div className="delete-actions-button" onClick={toggleModal}>
        <div className="delete-actions-button-icon">
          <FaRegTrashAlt />
        </div>
        <div className="delete-actions-button-text">Delete</div>
      </div>

      {isOpen && (
        <div className="delete_trainee_popup-overlay">
          <div className="delete_trainee_popup-content">
            <div
              onClick={toggleModal}
              className="delete_trainee_popup-content-close_button"
            >
              <FaTimes />
            </div>
            <div className="delete_trainee_popup-content-text">
              Are you sure you want to delete selected {idArray.length}{" "}
              {idArray.length === 1 ? "user" : "users"}?
            </div>

            <div className="delete_trainee_popup-content-action">
              <button
                className="delete_trainee_popup-content-action-confirm"
                onClick={handleConfirm}
              >
                Yes
              </button>
              <button
                className="delete_trainee_popup-content-action-cancel"
                onClick={toggleModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteTraineePopup;
